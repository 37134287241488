import React from 'react';
import { useDispatch } from "react-redux";
import { customLabel } from '../../utility/customLabel';
import { Tab, AppBar, Tabs, Paper } from "@material-ui/core";
import Details from "./Details";
import Vars from "./Vars";
import Checks from "./Checks";
import Stats from "./Stats";
import DocumentsPage from "../../documents/DocumentsPage";
import MachineEvents from "./MachineEvents";
import MeasureTools from "./MeasureTools";
import { mesStyles } from "../../utility/ultrafabStyles";
import ProcessEvents from './ProcessEvents';
import ComponentsPage from "../../operatorView/components/ComponentsPage"

const OdpHome = ({ loading, odp, editProcess, setReload, process, restoreProcessAPI, reopenProcessAPI,
    currentMachineEvents, loadingMachineEvents, reloadMachineEvents, resultsMachineEvents,
    setReloadMachineEvents, choosenVar, insertVarFlag, listVarFlag, currentVar, editVar, currentVars, processMachineVars,
    insertVarsAPI, changeCurrentVar, enableInsertVarFlag, enableVarFlag, disableVarFlag,
    disableInsertVarFlag, setVar, resetVar, addVar, createVar, updateVar, changeVar, drProcessEvents, loadingDrProcessEvents,
    reloadDrProcessEvents, resultsDrProcessEvents, setReloadDrProcessEvents, editCheck, currentChecks, changeCheck, updateCheckAPI,
    createCheckAPI, setCheck, addCheck, resetCheck, configuration, causalizeVar, causalizationObj }) => {
    const dispatch = useDispatch()
    const myClasses = mesStyles();
    const [tabIndex, setTabIndex] = React.useState(0);

    //sposta il tab i vari componenti della lavorazione
    const handleChangeTab = (event, newValue) => {
        dispatch(setReload())
        /*  if (newValue === 0) {
             dispatch(setReload());
         } */
        setTabIndex(newValue);
    };

    return (
        <React.Fragment>
            {odp.processes && odp.processes.length > 0 && editProcess ? (
                <AppBar position="static">
                    <Tabs value={tabIndex} onChange={handleChangeTab} style={{ backgroundColor: "white", color: "black" }} variant="scrollable" scrollButtons="auto"
                        className={myClasses.smallTabs}>
                        <Tab label={customLabel("function.details")} key={"details"} value={0} className={myClasses.smallTab} />
                        <Tab label={customLabel("machineEvent.machineEvents")} key={"machineEvents"} value={1} className={myClasses.smallTab} />
                        {!odp.maintenance ? <Tab label={customLabel("process.processEvents")} key={"processEvents"} value={2} className={myClasses.smallTab} /> : null}
                        {!odp.maintenance ? <Tab label={customLabel("process.stats")} key={"stats"} value={3} className={myClasses.smallTab} /> : null}
                        <Tab label={customLabel(odp.maintenance ? "maintenancePhase.vars" : "processType.var.vars")} key={"vars"} value={4} className={myClasses.smallTab} />
                        <Tab label={customLabel("component.components")} key={"components"} value={5} className={myClasses.smallTab} />
                        <Tab label={customLabel(odp.maintenance ? "maintenance.checks" : "processType.check.checks")} key={"checks"} value={6} className={myClasses.smallTab} />
                        <Tab label={customLabel("document.documents")} key={"documents"} value={7} className={myClasses.smallTab} />
                        {!odp.maintenance ? <Tab label={customLabel("measureTool.measureTools")} key={"measureTools"} value={8} className={myClasses.smallTab} /> : null}
                    </Tabs>
                </AppBar>
            ) : null}
            <div style={{ marginTop: "6px" }}></div>
            {odp.processes && odp.processes.length > 0 && editProcess ? (
                <React.Fragment>
                    {tabIndex === 0 ? (
                        <Details odp={odp} editProcess={editProcess} loading={loading} process={process}
                            restoreProcessAPI={restoreProcessAPI} reopenProcessAPI={reopenProcessAPI} />
                    ) : tabIndex === 1 ? (
                        <MachineEvents odp={odp} loading={loading} currentMachineEvents={currentMachineEvents} loadingMachineEvents={loadingMachineEvents}
                            reloadMachineEvents={reloadMachineEvents} resultsMachineEvents={resultsMachineEvents} editProcess={editProcess}
                            setReloadMachineEvents={setReloadMachineEvents} />
                    ) : tabIndex === 2 ? (
                        <ProcessEvents odp={odp} loading={loading} drProcessEvents={drProcessEvents} loadingDrProcessEvents={loadingDrProcessEvents}
                            reloadDrProcessEvents={reloadDrProcessEvents} resultsDrProcessEvents={resultsDrProcessEvents}
                            setReloadDrProcessEvents={setReloadDrProcessEvents} editProcess={editProcess} />
                    ) : tabIndex === 3 ? (
                        <Stats />
                    ) : tabIndex === 4 ? (
                        <Vars odp={odp} editProcess={editProcess} loading={loading} choosenVar={choosenVar} insertVarFlag={insertVarFlag} listVarFlag={listVarFlag}
                            currentVar={currentVar} editVar={editVar} currentVars={currentVars} processMachineVars={processMachineVars}
                            insertVarsAPI={insertVarsAPI} changeCurrentVar={changeCurrentVar} enableInsertVarFlag={enableInsertVarFlag} enableVarFlag={enableVarFlag}
                            disableVarFlag={disableVarFlag} disableInsertVarFlag={disableInsertVarFlag} setVar={setVar} resetVar={resetVar} addVar={addVar}
                            createVar={createVar} updateVar={updateVar} changeVar={changeVar} causalizeVar={causalizeVar} causalizationObj={causalizationObj} />
                    ) : tabIndex === 5 ? (
                        <Paper className={myClasses.processDetailsPaper}>
                            <ComponentsPage configuration={configuration} operatorView={false} />
                        </Paper>
                    ) : tabIndex === 6 ? (
                        <Checks odp={odp} editProcess={editProcess} loading={loading} editCheck={editCheck} currentChecks={currentChecks} changeCheck={changeCheck} resetCheck={resetCheck}
                            addCheck={addCheck} updateCheckAPI={updateCheckAPI} createCheckAPI={createCheckAPI} setCheck={setCheck} />
                    ) : tabIndex === 7 ? (
                        <Paper>
                            <DocumentsPage parentId={editProcess.id} parentType="processes" extLoading={loading} title={false} size="small" />
                        </Paper>
                    ) : tabIndex === 8 ? (
                        <MeasureTools />
                    ) : null
                    }
                </React.Fragment>)
                : null}
        </React.Fragment>
    )
};

export default OdpHome;
