import { Button, CircularProgress, Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { customLabel } from "../../utility/customLabel";
import moment from "moment";
import React, { useCallback, useEffect } from "react";
import Timeline, {
    DateHeader,
    SidebarHeader,
    TimelineHeaders,
    TimelineMarkers,
    TodayMarker,
} from "react-calendar-timeline";
import 'react-calendar-timeline/lib/Timeline.css';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useInterval from 'react-useinterval';
import { configurationSelector } from "../../configuration/slice";
import { useAnchor } from "../../customHooks/useAnchor";
import { getListAPI as getMachineEvents, machineEventsSelector } from '../../machineEvents/slice';
import LegendPreview from "../../utility/LegendPreview";
import PopoverLegend from "../../utility/PopoverLegend";
import { mesStyles } from "../../utility/ultrafabStyles";
import { getListAPI as getWorkcentresAPI, workcentresSelector } from "../../workcentres/slice";
import { loadMachineEvents, timelineSelector } from './slice';
import "./timeline.css";
import { EXTRAORDINARY_LABEL, EXTRA_LEGEND_ITEMS } from "./utility";

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: '#676767',
    },
    headerLeft: {
        backgroundColor: '#676767',
        width: 150,
        padding: theme.spacing(2),
        color: "#fff",
        fontWeight: "bold",
        fontSize: "14pt"
    },
    event: {
        margin: theme.spacing(2),
        padding: "5px",
        fontSize: "8pt",
        borderRadius: "5px"
    },
    button: {
        margin: theme.spacing() / 1,
    },
    legend: {
        margin: theme.spacing() / 1,
        textAlign: "right"
    },
    button_odp: {
        margin: theme.spacing() / 1,
        float: 'right',
        textAlign: 'left',
        justifyContent: 'flex-start',
        //width: 300
    },
    workcentre: {
        fontWeight: "bold"
    }
}));

let moved
let downListener = () => {
    moved = false
}
document.addEventListener('mousedown', downListener)
let moveListener = () => {
    moved = true
}
document.addEventListener('mousemove', moveListener)

let reloadTimeout;

const MachinesTimeline = ({ renderSecondaryTitle, renderMachineTitle, changeUrlOdps, changeUrlMaintenance, renderWorkcentreTitle }) => {
    const classes = useStyles();
    const myClasses = mesStyles();
    const dispatch = useDispatch();
    const { macEvents, procEvents, loading } = useSelector(timelineSelector);
    const { machineEvents } = useSelector(machineEventsSelector);
    const [groups, setGroups] = React.useState([]);
    const [items, setItems] = React.useState([]);
    const [selectedOdp, setSelectedOdp] = React.useState(false);
    const [selectedMaintenance, setSelectedMaintenance] = React.useState(false);
    const [link, setLink] = React.useState("");
    const [paused, setPaused] = React.useState(false);
    const [refresh, setRefresh] = React.useState(true);
    const [visibleTimeStart, setVisibleTimeStart] = React.useState(moment().add(-1, 'day').startOf('day').unix() * 1000);
    const [visibleTimeEnd, setVisibleTimeEnd] = React.useState(moment().endOf('day').unix() * 1000);
    const { workcentres } = useSelector(workcentresSelector);
    const [anchorPopover, { handleOpen: handleOpenPopover, handleClose: handleClosePopover }, openPopover] = useAnchor();
    const [eventDuration, setEventDuration] = React.useState(300);
    const { configuration } = useSelector(configurationSelector);

    const redesignItems = useCallback(() => {
        let ni = [];
        let machineLast = {}
        for (let i in macEvents) {
            let event = macEvents[i];
            var item = {};
            item.id = event.id;
            if (event.tipo === "produzione in pausa") {
                item.paused = true
            } else {
                item.paused = false
            }
            if (event.tipo === "VARIO") {
                if (event.maintenance === 0) {
                    item.title = event.code + renderSecondaryTitle(event)
                } else {
                    if (event.name === EXTRAORDINARY_LABEL) {
                        item.title = customLabel("maintenance.extraordinary").toUpperCase()
                    } else {
                        item.title = event.code
                    }
                }
                item.backgroundColor = event.backgroundColor;
                item.disactiveBackgroundColor = event.disactiveBackgroundColor;
                if (selectedOdp && event.code !== selectedOdp.code) {
                    item.bgColor = item.disactiveBackgroundColor;
                    item.background = item.disactiveBackgroundColor;
                    item.selectedBgColor = item.disactiveBackgroundColor;
                    item.color = "#B6B6B6"
                } else {
                    item.bgColor = item.backgroundColor;
                    item.background = item.backgroundColor;
                    item.selectedBgColor = item.backgroundColor;
                    item.color = "#000"
                }
            } else if (event.stapling) {
                item.title = event.code;
                item.backgroundColor = event.backgroundColor;
                item.disactiveBackgroundColor = event.disactiveBackgroundColor;
                if ((selectedOdp && event.code !== selectedOdp.code) || (selectedMaintenance && event.code !== selectedMaintenance.code)) {
                    item.bgColor = item.disactiveBackgroundColor;
                    item.background = item.disactiveBackgroundColor;
                    item.selectedBgColor = item.disactiveBackgroundColor;
                    item.color = "#B6B6B6"
                } else {
                    item.bgColor = item.backgroundColor;
                    item.background = item.backgroundColor;
                    item.selectedBgColor = item.backgroundColor;
                    item.color = "#000"
                }
            } else {
                if (event.maintenance === 0) {
                    item.title = event.code + renderSecondaryTitle(event)
                } else {
                    if (event.name === EXTRAORDINARY_LABEL) {
                        item.title = customLabel("maintenance.extraordinary").toUpperCase()
                    } else {
                        item.title = event.code
                    }
                }
                item.backgroundColor = event.backgroundColor;
                item.disactiveBackgroundColor = event.disactiveBackgroundColor;
                if ((selectedOdp && event.code !== selectedOdp.code) || (selectedMaintenance && event.code !== selectedMaintenance.code)) {
                    item.bgColor = item.disactiveBackgroundColor;
                    item.background = item.disactiveBackgroundColor;
                    item.selectedBgColor = item.disactiveBackgroundColor;
                    item.color = "#B6B6B6"
                } else {
                    item.bgColor = item.backgroundColor;
                    item.background = item.backgroundColor;
                    item.selectedBgColor = item.backgroundColor;
                    item.color = "#000"
                }
            }
            if (event.maintenance === 0 || event.maintenance === null) {
                item.group = event.machineId;
            } else {
                item.group = event.machineId + "_MAN";
            }
            item.start_time = moment(new Date(event.startDate));
            if (event.endDate != null) {
                item.end_time = moment(new Date(event.endDate));
            } else {
                item.end_time = moment();
            }
            if (machineLast[item.group] === undefined || item.start_time.diff(machineLast[item.group]) < 0) {
                machineLast[item.group] = item.start_time
            }
            let odp = { stapling: event.stapling, id: event.odpId !== null ? event.odpId : event.staplingId, code: event.code, maintenance: event.maintenance }
            item.itemProps = {
                'odp': odp
            };
            ni.push(item);
        }
        for (let i in procEvents) {
            let event = procEvents[i];
            var item2 = {};
            item2.id = event.id;
            item2.paused = false
            if (event.stapling) {
                item2.title = event.code;
                if ((selectedOdp && event.code !== selectedOdp.code) || (selectedMaintenance && event.code !== selectedMaintenance.code)) {
                    item2.color = "#B6B6B6"
                } else {
                    item2.color = "#000"
                }
            } else {
                item2.title = event.code + renderSecondaryTitle(event)
                item2.backgroundColor = event.backgroundColor;
                item2.disactiveBackgroundColor = event.disactiveBackgroundColor;
                if ((selectedOdp && event.code !== selectedOdp.code) || (selectedMaintenance && event.code !== selectedMaintenance.code)) {
                    item2.color = "#B6B6B6"
                } else {
                    item2.color = "#000"
                }
            }
            item2.group = event.machineId;
            item2.start_time = moment(new Date(event.startDate));
            if (event.endDate != null && machineLast[item2.group] !== undefined && moment(new Date(event.endDate)).diff(machineLast[item.group]) < 0) {
                item2.end_time = moment(new Date(event.endDate));
            } else if (machineLast[item2.group] !== undefined) {
                item2.end_time = machineLast[item2.group]
            } else {
                item2.end_time = moment(new Date(event.endDate));
            }
            let odp = { processEvent: true, stapling: 0, id: event.odpId !== null ? event.odpId : event.staplingId, code: event.code, maintenance: 0 }
            item2.itemProps = {
                'odp': odp
            };
            ni.push(item2);
        }
        setItems(ni);
        return ni
    }, [renderSecondaryTitle, macEvents, procEvents, selectedOdp, selectedMaintenance]);


    const redesignGroups = useCallback(() => {
        let gruppi = groups
        for (let i in macEvents) {
            let event = macEvents[i]
            if (event.maintenance === 1) {
                let gruppom = gruppi.findIndex(g => {
                    return g.id === event.machineId + "_MAN";
                });
                let gruppoor = gruppi.findIndex(g => {
                    return g.id === event.machineId;
                });
                if (gruppom < 0) {
                    let g = {};
                    g.id = event.machineId + "_MAN";
                    g.title = gruppi[gruppoor].title + " manutenzioni"
                    g.workcentreId = gruppi[gruppoor].workcentreId
                    gruppi.splice(gruppoor + 1, 0, g);
                }
            }
        }
        return gruppi
    }, [groups, macEvents])

    useEffect(() => {
        dispatch(getWorkcentresAPI(0, 10000, "code", "asc", false, false));
        dispatch(getMachineEvents(0, 1000, "name", "asc", ""));
        dispatch(loadMachineEvents(moment().add(-2, 'day').startOf('day').valueOf(), moment().add(12, 'hour').valueOf(), 300))
    }, [dispatch]);

    useEffect(() => {
        let ni = redesignItems()
        setItems(ni);
    }, [groups, paused, redesignItems])

    useEffect(() => {
        // ridisegna in caso sia stato fatto un refresh 
        if (groups.length !== 0 && refresh && macEvents.length !== 0) {
            let gruppi = redesignGroups()
            setGroups(gruppi)
            setRefresh(false)
            let ni = redesignItems()
            setItems(ni);
        }
    }, [groups, refresh, macEvents, redesignItems, redesignGroups])

    // primo design gruppi
    useEffect(() => {
        if ((groups.length === 0 && workcentres !== false && workcentres.length > 0)) {
            let ng = []
            for (let w in workcentres) {
                let item = {};
                item.id = "W" + workcentres[w].id;
                item.title = renderWorkcentreTitle(workcentres[w])
                item.workcentreId = workcentres[w].id;
                ng.push(item);
                let toRemove = true
                for (let m in workcentres[w].machines) {
                    let item = {};
                    item.id = workcentres[w].machines[m].id;
                    item.title = renderMachineTitle(workcentres[w].machines[m]);
                    item.workcentreId = workcentres[w].id;
                    if (!workcentres[w].machines[m].external || configuration.showExtTimeline) {
                        ng.push(item);
                        toRemove = false
                    }
                }
                if (toRemove) {
                    let gruppom = ng.findIndex(g => {
                        return g.workcentreId === workcentres[w].id
                    });
                    ng.splice(gruppom, 1);
                }
            }
            for (let i in macEvents) {
                let event = macEvents[i]
                if (event.maintenance === 1) {
                    let gruppom = ng.findIndex(g => {
                        return g.id === event.machineId + "_MAN";
                    });
                    let gruppoor = ng.findIndex(g => {
                        return g.id === event.machineId;
                    });
                    if (gruppom < 0) {
                        let g = {};
                        g.id = event.machineId + "_MAN";
                        g.title = ng[gruppoor].title + " manutenzioni"
                        g.workcentreId = ng[gruppoor].workcentreId
                        ng.splice(gruppoor + 1, 0, g);
                    }
                }
            }
            setGroups(ng)
        }
    }, [groups, renderMachineTitle, workcentres, macEvents, configuration, renderWorkcentreTitle])

    useInterval(() => {
        setRefresh(true)
        reloadMachineEvents(visibleTimeStart, visibleTimeEnd, eventDuration)
    }, 10000);


    const onItemSelect = (itemId, e, time) => {
        let i = items.findIndex(e => {
            return e.id === itemId;
        });
        let odp = items[i].itemProps.odp;
        if (odp.maintenance === 1) {
            setSelectedMaintenance(odp);
            setSelectedOdp(false)
            setLink("maintenances/" + odp.id);
        } else {
            setSelectedOdp(odp);
            setSelectedMaintenance(false)
            setLink("odps/" + odp.id);
        }
    }
    const onItemClick = (itemId, e, time) => {
        let i = items.findIndex(e => {
            return e.id === itemId;
        });
        let odp = items[i].itemProps.odp;
        if (selectedOdp && selectedOdp.code === odp.code) {
            setSelectedOdp(false)
        } else if (selectedMaintenance && selectedMaintenance.code === odp.code) {
            setSelectedMaintenance(false)
        } else {
            if (odp.maintenance === 1) {
                setSelectedMaintenance(odp);
                setSelectedOdp(false)
                setLink("maintenances/" + odp.id);
            } else {
                setSelectedOdp(odp);
                setSelectedMaintenance(false)
                setLink("odps/" + odp.id);
            }
        }
    }

    const onCanvasClick = (groupId) => {
        setSelectedOdp(false)
        setSelectedMaintenance(false)
    }

    const myFormat = {
        year: { long: 'YYYY', mediumLong: 'YYYY', medium: 'YYYY', short: 'YYYY' },
        month: { long: 'MM/YYYY', mediumLong: 'MM/YYYY', medium: 'MM/YY', short: 'MM' },
        week: { long: 'DD/MM', mediumLong: 'DD/MM', medium: 'DD/MM', short: 'DD/MM' },
        day: { long: 'DD/MM/YYYY', mediumLong: 'DD/MM/YY', medium: 'DD', short: 'DD' },
        hour: { long: 'DD/MM/YYYY HH:00', mediumLong: 'DD/MM, HH:00', medium: 'HH:00', short: 'HH' },
        minute: { long: 'DD/MM/YYYY HH:mm', mediumLong: 'HH:mm', medium: 'HH:mm', short: 'mm', },
        second: { long: 'HH:mm:ss', mediumLong: 'HH:mm:ss', medium: 'mm:ss', short: 'ss', }
    }
    const formatLabel = ([timeStart, timeEnd], unit, labelWidth, formatOptions: LabelFormat = myFormat) => {
        let format
        if (labelWidth >= 150) {
            format = formatOptions[unit]['long']
        } else if (labelWidth >= 100) {
            format = formatOptions[unit]['mediumLong']
        } else if (labelWidth >= 50) {
            format = formatOptions[unit]['medium']
        } else {
            format = formatOptions[unit]['short']
        }
        return timeStart.format(format)
    }

    const changeBackgrounds = (odp) => {
        for (let i in items) {
            let it = items[i]
            if ((odp !== false && odp.code === it.itemProps.odp.code) || odp === false) {
                it.bgColor = it.backgroundColor
                it.color = "#000"
            } else {
                it.bgColor = it.disactiveBackgroundColor
                it.color = "#B6B6B6"
            }
            items[i] = it
        }
        setItems(items)
    }

    const itemRenderer = ({ item, timelineContext, itemContext, getItemProps, getResizeProps }) => {
        const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
        if (paused || !item.paused) {
            return (
                <div
                    {...getItemProps({
                        style: {
                            backgroundColor: item.itemProps.odp.processEvent ? "#00b660" : item.itemProps.odp.stapling ? "repeating-linear-gradient(135deg," + item.bgColor + "," + item.bgColor + " 10px, " + item.disactiveBackgroundColor + " 10px, " + item.disactiveBackgroundColor + " 20px)" : item.bgColor === "VARIO" ? "repeating-linear-gradient(135deg,white,#94edfb 10px, white 10px, #94edfb 20px)" : item.bgColor,
                            background: item.itemProps.odp.processEvent ? "#00b660" : item.itemProps.odp.stapling ? "repeating-linear-gradient(135deg," + item.bgColor + "," + item.bgColor + " 10px, " + item.disactiveBackgroundColor + "10px, " + item.disactiveBackgroundColor + " 20px)" : item.bgColor === "VARIO" ? "repeating-linear-gradient(135deg,white,#94edfb 10px, white 10px, #94edfb 20px)" : item.bgColor,
                            color: item.color,
                            borderColor: "#4c4c4c",
                            borderStyle: "solid",
                            borderWidth: 1,
                            borderRadius: 4,
                            borderLeftWidth: 1,
                            borderRightWidth: 1,
                            textAlign: item.itemProps.odp.processEvent ? "right" : "left"
                        },
                        onMouseUp: () => {
                            if (!moved) {
                                if (selectedOdp && selectedOdp.code === item.itemProps.odp.code) {
                                    //setSelectedOdp(false);
                                    changeBackgrounds(false);
                                } else if (selectedMaintenance && selectedMaintenance.code === item.itemProps.odp.code) {
                                    //setSelectedMaintenance(false);
                                    changeBackgrounds(false);
                                } else {
                                    /*
                                    if (item.props !== undefined && item.props.odp.maintenance) {
                                        setSelectedMaintenance(item.itemProps.odp);
                                    } else {
                                        setSelectedOdp(item.itemProps.odp);
                                    }
                                    */
                                    changeBackgrounds(item.itemProps.odp);
                                }
                            }
                        }
                    })}
                >
                    {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

                    <div
                        style={{
                            height: itemContext.dimensions.height,
                            overflow: "hidden",
                            paddingLeft: 3,
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap"
                        }}
                    >
                        {itemContext.title}
                    </div>

                    {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
                </div>
            );
        } else {
            return null;
        }
    };

    const togglePaused = () => {
        setPaused(!paused);
    }

    const toggleOdp = () => {
        setSelectedOdp(false);
        setSelectedMaintenance(false)
        setRefresh(true)
    }

    const reloadMachineEvents = (vts, vte, duration) => {
        if (duration !== undefined) {
            dispatch(loadMachineEvents(vts.toFixed(), vte.toFixed(), duration))
        } else {
            dispatch(loadMachineEvents(vts.toFixed(), vte.toFixed(), eventDuration))
        }
    }

    const onTimeChange = (vts, vte, updateScrollCanvas) => {
        if (reloadTimeout !== undefined) {
            clearTimeout(reloadTimeout)
        }
        setVisibleTimeStart(vts)
        setVisibleTimeEnd(vte)
        updateScrollCanvas(vts, vte)

        let diff = moment.duration(moment(vte).diff(moment(vts)))
        let durata = 12000
        if (diff._data.years > 0) {
            durata = 600000
        } else if (diff._data.months > 0 && diff._data.years === 0) {
            durata = 12000
        } else if (diff._data.days > 7) {
            durata = 3000
        } else if (diff._data.days > 0) {
            durata = 600
        } else if (diff._data.hours > 2) {
            durata = 300
        } else {
            durata = 0
        }
        setEventDuration(durata)
        let rl = setTimeout(() => {
            reloadMachineEvents(vts, vte, durata)
            setRefresh(true)
        }, 250)
        reloadTimeout = rl;
    }

    const groupRenderer = ({ group }) => {
        return (
            <div>
                <span className={group.id.toString().startsWith("W") ? classes.workcentre : null}>{group.title}</span>
            </div>
        )
    }

    return (
        <React.Fragment>
            <Grid container alignContent="space-around">
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    {groups && groups.length > 0 ? (
                        <Button variant={paused ? "contained" : "outlined"} color={paused ? "primary" : "default"} className={classes.button} onClick={togglePaused}>
                            {customLabel("timeline.showPaused")}
                        </Button>
                    ) : null}
                </Grid>
                <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                    {loading ? <CircularProgress /> : null}
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Button variant="outlined" color="secondary" className={classes.button_odp} onClick={toggleOdp}>
                        {selectedMaintenance ? (
                            <React.Fragment>
                                {customLabel("timeline.selectedMaintenance")}: {selectedMaintenance.code}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {customLabel("timeline.selectedOdp")}: {selectedOdp.extCode && selectedOdp.extCode !== "" && selectedOdp.extCode !== null ? selectedOdp.code + " (" + selectedOdp.extCode + ")" : selectedOdp.code}
                            </React.Fragment>
                        )}
                    </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                    <Button variant="outlined" className={classes.button} disabled={!selectedOdp && !selectedMaintenance} component={Link} to={link}
                        onClick={link.split("/")[0] === 'odps' ? changeUrlOdps : changeUrlMaintenance}>
                        {customLabel("odp.details")}
                    </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    <div className={classes.legend}>
                        <div className={myClasses.dashedUnderline} onMouseEnter={(e) => handleOpenPopover(e.currentTarget, false)} onMouseLeave={handleClosePopover}>
                            <LegendPreview name={customLabel("legend.legend")} />
                        </div>
                    </div>
                </Grid>
            </Grid>
            {
                groups.length !== 0 ? (
                    <div className={myClasses.divTimeline}>
                        <Timeline
                            groups={groups}
                            horizontalLineClassNamesForGroup={group =>
                                group.id.toString().startsWith("W") ? ["highlight"] : ""
                            }
                            groupRenderer={groupRenderer}
                            items={items}
                            minZoom={60 * 1000}
                            itemHeightRatio={0.9}
                            traditionalZoom={true}
                            onTimeChange={onTimeChange}
                            itemRenderer={itemRenderer}
                            defaultTimeStart={moment.unix(visibleTimeStart)}
                            defaultTimeEnd={moment.unix(visibleTimeEnd)}
                            visibleTimeStart={visibleTimeStart}
                            visibleTimeEnd={visibleTimeEnd}
                            onItemSelect={onItemSelect}
                            onItemClick={onItemClick}
                            onCanvasClick={onCanvasClick}
                        >
                            <TimelineHeaders className={myClasses.headerTimeline}>
                                <SidebarHeader style={{ backgroundColor: "red" }}>
                                    {({ getRootProps }) => {
                                        return <div className={classes.headerLeft}>{customLabel("machine.machines")}</div>
                                    }}
                                </SidebarHeader>
                                <DateHeader unit="primaryHeader" labelFormat={formatLabel} className={classes.header} />
                                <DateHeader labelFormat={formatLabel} />
                            </TimelineHeaders>
                            <TimelineMarkers>
                                <TodayMarker />
                            </TimelineMarkers>
                        </Timeline>
                    </div>
                ) : null
            }
            {anchorPopover ? (
                <PopoverLegend name={customLabel("legend.legend").toUpperCase()} list={machineEvents ? machineEvents.concat(EXTRA_LEGEND_ITEMS) : []} open={openPopover} anchorEl={anchorPopover}
                    openPopover={handleOpenPopover} closePopover={handleClosePopover}
                    anchorVertical={"bottom"} anchorHorizontal={"right"} transformVertical={"top"} transormHorizontal={"left"}
                />
            ) : null}
        </React.Fragment>
    );
};

export default MachinesTimeline;
