import { Grid } from "@material-ui/core";
import _ from 'lodash';
import moment from "moment";
import React from "react";
import { bishopFormats } from "../../../constants";
import { labelArticleOpView, labelMachineOpView, labelOperatorOpView, labelWorkcentreOpView, odpDetailsOpViewLabels } from "../../configuration/utility";
import { customLabel } from '../../utility/customLabel';

/**
 * In base alla configurazione di labelArticleOpView ritorna la stringa per mostrare l'articolo
 * @param {object} configuration 
 * @param {object} article 
 * @returns stringa identificativa dell'articolo
 */
export const renderArticleLabel = (configuration, article) => {
    switch (configuration.labelArticleOpView) {
        case labelArticleOpView["code"]: return article.code
        case labelArticleOpView["codeName"]: return article.code + " - " + article.name
        case labelArticleOpView["name"]: return article.name
        default: return article.code;
    }
}

export const renderWorkcentreLabel = (configuration, workcentre) => {
    switch (configuration.labelWorkcentreOpView) {
        case labelWorkcentreOpView["code"]: return workcentre.code
        case labelWorkcentreOpView["codeName"]: return workcentre.code + " - " + workcentre.name
        case labelWorkcentreOpView["name"]: return workcentre.name
        default: return workcentre.code;
    }
}

export const renderMachineLabel = (configuration, machine) => {
    switch (configuration.labelMachineOpView) {
        case labelMachineOpView["code"]: return machine.code
        case labelMachineOpView["codeName"]: return machine.code + " - " + machine.name
        case labelMachineOpView["name"]: return machine.name
        default: return machine.code;
    }
}

export const renderOperatorLabel = (configuration, operator) => {
    switch (configuration.labelOperatorOpView) {
        case labelOperatorOpView["code"]: return operator.code
        case labelOperatorOpView["codeUsername"]: return operator.code + " - " + operator.username
        case labelOperatorOpView["name"]: return operator.firstName + " "+operator.lastName
        default: return operator.code;
    }
}

/**
 * Calcola il prossima currentValue, una volta inserito un controllo
 * @param {array} currentChecks 
 * @param {array} currentVars 
 * @returns valore del currentValue
 */
export const getCurrentValue = (currentChecks, currentVars) => {
    let varCheck = false;
    if (currentChecks && currentChecks.length > 0 && currentChecks[0].var_name !== "" && currentChecks[0].var_name !== null) {
        let indexVar = currentVars.findIndex((v) => {
            return v.name.toString() === currentChecks[0].var_name.toString();
        })
        if (indexVar !== -1) {
            varCheck = currentVars[indexVar];
            if (varCheck.value !== null && varCheck.editable) {
                if (varCheck.type === "boolean" && varCheck.value === "true") {
                    return true;
                }
                if (varCheck.type === "boolean" && varCheck.value === "false") {
                    return false;
                }
                if (varCheck.type !== "boolean") {
                    return varCheck.value;
                }
            } else {
                return null;
            }
        }
    } else if (currentChecks && currentChecks.length > 0 && currentChecks[0].type === "link") {
        return true
    } else {
        return null
    }
}
/**
 * Mostra etichetta del odp in base a come è stato impostato nella configurazione
 * @param {object} o odp del quale si vuole mostrare la label
 * @returns stringa identificativa dell'odp
 */
export const renderOdpLabelInput = (o, configuration) => {
    let aString = []
    let odpString
    configuration.labelOdpOpView.split(",").forEach((item) => {
        if (((_.get(o, item)) !== "" && (_.get(o, item)) !== null && item !== "priority") || ((_.get(o, item)) !== "" && (_.get(o, item)) !== null && item === "priority" && (_.get(o, item)) < 10)) {
            if (item !== "priority") {
                aString.push(_.get(o, item));
            } else {
                let p = _.get(o, item) + 1
                let priorityLabel = customLabel("odp.priority") + " " + (p);
                aString.push(priorityLabel);
            }
            odpString = aString.join(" - ")
        }
    })
    return odpString
}
/**
 * Uso la configurazione di odplabel per decidere come mostrare le lavorazioni per la pinzatura, no lavorazioni normali
 * @param {object} p process del quale si vuole mostrare la label
 * @param {object} configuration 
 * @returns 
 */
export const renderProcessLabel = (p, configuration) => {
    let aString = []
    let odpString
    configuration.labelOdpOpView.split(",").forEach((item) => {
        if (((_.get(p, item)) !== "" && (_.get(p, item)) !== null && (_.get(p.odp, item)) !== "" && (_.get(p.odp, item)) !== null && item !== "priority") || ((_.get(p, item)) !== "" && (_.get(p, item)) !== null && (_.get(p.odp, item)) !== "" && (_.get(p.odp, item)) !== null && item === "priority" && (_.get(p, item)) < 10)) {
            if ((item === "customer.code" || item === "customer.name" || item === "extCode" || item === "customerOrder.code") && item !== "priority") {
                aString.push(_.get(p.odp, item));
            } else if (item === "priority") {
                let priority = _.get(p, item) + 1
                let priorityLabel = customLabel("odp.priority") + " " + (priority);
                aString.push(priorityLabel);
            } else {
                aString.push(_.get(p, item));
            }
            odpString = aString.join(" - ")
        }
    })
    return odpString
}

export const orderDetailsOdp = (configuration, odp) => {
    //configurazione splittata
    let orderedLabels = configuration.labelOdpDetailsOpView.split(",")
    return (
        <Grid container size="small" style={{ textAlign: "left", maxHeight: "60px", overflow: "hidden" }}>
            {orderedLabels.map((field) => {
                let indexLabel = odpDetailsOpViewLabels.findIndex((l) => {
                    return field === l.id;
                })
                if (indexLabel !== -1) {
                    let selectedLabel = odpDetailsOpViewLabels[indexLabel];
                    //per ogni etichetta in configurazione mostro la relativa chiave valore
                    return (
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} key={field}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <b>{customLabel(selectedLabel.translatedLabel)}</b>
                                <span style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "150px", minWidth: "150px", display: "inline-block" }}>
                                    : {getOdpDetailsField(odp, selectedLabel, configuration)}
                                </span>
                            </div>
                        </Grid>
                    )
                } else {
                    return null
                }
            })}
        </Grid >
    )
}
const getOdpDetailsField = (odp, selectedLabel, configuration) => {
    if (!_.get(odp, selectedLabel.id)) {
        return null;
    }
    if (!selectedLabel.date) {
        return (
            _.get(odp, selectedLabel.id)
        )
    }
    if (selectedLabel.date && selectedLabel.id !== "deliveryDate") {
        return (
            moment(_.get(odp, selectedLabel.id)).format(bishopFormats.LTS)
        )
    }
    if (selectedLabel.date && selectedLabel.id === "deliveryDate") {
        return (
            configuration && configuration.showOdpDeliveryTime ? moment(_.get(odp, selectedLabel.id)).format(bishopFormats.LT) : moment(_.get(odp, selectedLabel.id)).format(bishopFormats.L)
        )
    }
}