import {
    Box, Checkbox,
    FormControl,
    FormControlLabel,
    Input,
    InputLabel,
    MenuItem,
    Select,
} from "@material-ui/core";
import React from "react";
import { customLabel } from "../../utility/customLabel";
import { mesStyles } from "../../utility/ultrafabStyles";
import { labelArticleOpView, labelMachineOpView, labelOperatorOpView, labelWorkcentreOpView, odpDetailsOpViewLabels, odpOpViewLabels } from "../utility";

const Operator = ({ configuration }) => {
    const myClasses = mesStyles();

    const configOdpOpViewSaved = configuration.labelOdpOpView.split(",");
    const labelOdpOpViewSaved = odpOpViewLabels.filter(l => configOdpOpViewSaved.includes(l.id)).map(l => customLabel(l.translatedLabel));

    const configOdpDetailsOpViewSaved = configuration.labelOdpDetailsOpView.split(",");
    const labelOdpDetailsOpViewSaved = odpDetailsOpViewLabels.filter(l => configOdpDetailsOpViewSaved.includes(l.id)).map(l => customLabel(l.translatedLabel));

    return (
        <Box className={myClasses.confDetailsBox}>
            <form className={myClasses.formContainer} noValidate autoComplete="off">
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.showUsers} name="showUsers" color="default" />
                    }
                    label={customLabel("configuration.showUsers")}
                />
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.askUserEvent} name="askUserEvent" color="default" />
                    }
                    label={customLabel("configuration.askUserEvent")}
                />
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.showCustomerInterface} name="showCustomerInterface" color="default" />
                    }
                    label={customLabel("configuration.showCustomerInterface")}
                />
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.showAlerts} name="showAlerts" color="default" />
                    }
                    label={customLabel("configuration.showAlerts")}
                />
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.enableInputOperator} name="enableInputOperator" color="default" />
                    }
                    label={customLabel("configuration.enableInputOperator")}
                />
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.opViewEnableOdpSearch} name="opViewEnableOdpSearch" color="default" />
                    }
                    label={customLabel("configuration.opViewEnableOdpSearch")}
                />
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.showExtMachines} name="showExtMachines" color="default" />
                    }
                    label={customLabel("configuration.showExtMachines")}
                />
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.enableInsertExtCode} name="enableInsertExtCode" color="default" />
                    }
                    label={customLabel("configuration.enableInsertExtCode")}
                />
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.blockingChecks} name="blockingChecks" color="default" />
                    }
                    label={customLabel("configuration.blockingChecks")}
                />
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.showOdpQtyPreview} name="showOdpQtyPreview" color="default" />
                    }
                    label={customLabel("configuration.showOdpQtyPreview")}
                />
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.showOdpRemainingQuantity} name="showOdpRemainingQuantity" color="default" />
                    }
                    label={customLabel("configuration.showOdpRemainingQuantity")}
                />
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.autoPopupJustify} name="autoPopupJustify" color="default" />
                    }
                    label={customLabel("configuration.autoPopupJustify")}
                />
                <FormControlLabel disabled
                    control={
                        <Checkbox checked={configuration.enableJustifyDialogColor} name="enableJustifyDialogColor" color="default" />
                    }
                    label={customLabel("configuration.enableJustifyDialogColor")}
                />
                <br />
                <FormControl disabled className={myClasses.form50}>
                    <InputLabel htmlFor="variablePercentualeOk">{customLabel("configuration.variablePercentualeOk")}</InputLabel>
                    <Input value={configuration.variablePercentualeOk ? configuration.variablePercentualeOk : ""} />
                </FormControl>
                <br />
                <FormControl disabled className={myClasses.form50}>
                    <InputLabel htmlFor="opViewOdpListSize">{customLabel("configuration.opViewOdpListSize")}</InputLabel>
                    <Input value={configuration.opViewOdpListSize ? configuration.opViewOdpListSize : ""} />
                </FormControl>
                <FormControl disabled className={myClasses.form50}>
                    <InputLabel htmlFor="nextMode">{customLabel("configuration.odpSortOrder")}</InputLabel>
                    <Select name="odpSortOrder" value={configuration.odpSortOrder}>
                        <MenuItem value="priority">{customLabel("process.priority")}</MenuItem>
                        <MenuItem value="deliveryDate">{customLabel("odp.deliveryDate")}</MenuItem>
                        <MenuItem value="code">{customLabel("odp.code")}</MenuItem>
                    </Select>
                </FormControl>
                <FormControl disabled className={myClasses.form50}>
                    <InputLabel htmlFor="labelArticleOpView">{customLabel("configuration.labelArticleOpView.labelArticleOpView")}</InputLabel>
                    <Select name="labelArticleOpView" value={configuration.labelArticleOpView}>
                        <MenuItem value={labelArticleOpView["code"]}>{customLabel("configuration.labelArticleOpView.code")}</MenuItem>
                        <MenuItem value={labelArticleOpView["codeName"]}>{customLabel("configuration.labelArticleOpView.codeName")}</MenuItem>
                        <MenuItem value={labelArticleOpView["name"]}>{customLabel("configuration.labelArticleOpView.name")}</MenuItem>
                    </Select>
                </FormControl>
                <FormControl disabled className={myClasses.form50}>
                    <InputLabel htmlFor="labelWorkcentreOpView">{customLabel("configuration.labelWorkcentreOpView.labelWorkcentreOpView")}</InputLabel>
                    <Select name="labelWorkcentreOpView" value={configuration.labelWorkcentreOpView}>
                        <MenuItem value={labelWorkcentreOpView["code"]}>{customLabel("configuration.labelWorkcentreOpView.code")}</MenuItem>
                        <MenuItem value={labelWorkcentreOpView["codeName"]}>{customLabel("configuration.labelWorkcentreOpView.codeName")}</MenuItem>
                        <MenuItem value={labelWorkcentreOpView["name"]}>{customLabel("configuration.labelWorkcentreOpView.name")}</MenuItem>
                    </Select>
                </FormControl>
                <FormControl disabled className={myClasses.form50}>
                    <InputLabel htmlFor="labelMachineOpView">{customLabel("configuration.labelMachineOpView.labelMachineOpView")}</InputLabel>
                    <Select name="labelMachineOpView" value={configuration.labelMachineOpView}>
                        <MenuItem value={labelMachineOpView["code"]}>{customLabel("configuration.labelMachineOpView.code")}</MenuItem>
                        <MenuItem value={labelMachineOpView["codeName"]}>{customLabel("configuration.labelMachineOpView.codeName")}</MenuItem>
                        <MenuItem value={labelMachineOpView["name"]}>{customLabel("configuration.labelMachineOpView.name")}</MenuItem>
                    </Select>
                </FormControl>
                <FormControl disabled className={myClasses.form50}>
                    <InputLabel htmlFor="labelOperatorOpView">{customLabel("configuration.labelOperatorOpView.labelOperatorOpView")}</InputLabel>
                    <Select name="labelOperatorOpView" value={configuration.labelOperatorOpView}>
                        <MenuItem value={labelOperatorOpView["code"]}>{customLabel("configuration.labelOperatorOpView.code")}</MenuItem>
                        <MenuItem value={labelOperatorOpView["codeUsername"]}>{customLabel("configuration.labelOperatorOpView.codeUsername")}</MenuItem>
                        <MenuItem value={labelOperatorOpView["name"]}>{customLabel("configuration.labelOperatorOpView.name")}</MenuItem>
                    </Select>
                </FormControl>
                <FormControl disabled className={myClasses.form50}>
                    <InputLabel htmlFor="labelOdpOpView">{customLabel("configuration.labelOdpOpView.labelOdpOpView")}</InputLabel>
                    <Input name="labelOdpOpView" value={labelOdpOpViewSaved.join(", ")} />
                </FormControl>
                <FormControl disabled className={myClasses.form50}>
                    <InputLabel htmlFor="labelOdpDetailsOpView">{customLabel("configuration.labelOdpDetailsOpView.labelOdpDetailsOpView")}</InputLabel>
                    <Input name="labelOdpDetailsOpView" value={labelOdpDetailsOpViewSaved.join(", ")} />
                </FormControl>
            </form>
        </Box>
    );
};
export default Operator;