import React from 'react'
import { customLabel } from "../utility/customLabel";
import { InputLabel, Select, MenuItem, FormControl, FormControlLabel, Checkbox, Input } from "@material-ui/core";
import { HuePicker } from "react-color";
import { mesStyles } from "../utility/ultrafabStyles";

const CheckForm = ({ maintenance, processCheck, onChange, vars, configuration }) => {
    const myClasses = mesStyles();

    const handleChangeComplete = (color) => {
        //active color
        let c = "rgba(" + color.rgb.r + "," + color.rgb.g + "," + color.rgb.b + ",1)";
        let e = { preventDefault: function () { }, persist: function () { } };
        let t = { name: "color", value: c };
        e["target"] = t;
        onChange(e);
    };

    return (
        <form className={myClasses.dialogForm} noValidate autoComplete="off">
            <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="name">{customLabel("processType.name")}</InputLabel>
                <Input name="name" value={processCheck ? processCheck.name : ""} autoFocus onChange={onChange} error={processCheck.name === ""} />
            </FormControl>
            <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="type">{customLabel("processType.check.type")}</InputLabel>
                <Select name="type" value={processCheck.type} onChange={onChange} error={processCheck.type === ""} >
                    <MenuItem value="boolean">{customLabel("function.boolean")}</MenuItem>
                    <MenuItem value="string">{customLabel("function.string")}</MenuItem>
                    <MenuItem value="number">{customLabel("function.number")}</MenuItem>
                    {!maintenance ? (
                        <MenuItem value="var">{customLabel("function.var").toLowerCase()}</MenuItem>
                    ) : null}
                    {!maintenance ? (
                        <MenuItem value="component">{customLabel("function.component").toLowerCase()}</MenuItem>
                    ) : null}
                    <MenuItem value="link">{customLabel("function.link")}</MenuItem>
                </Select>
            </FormControl>
            {processCheck.type === "var" ? (
                <FormControl margin="normal" fullWidth>
                    <InputLabel htmlFor="varName">{customLabel("function.var")}</InputLabel>
                    <Select name="varName" onChange={onChange}
                        value={processCheck.varName !== "" && processCheck.varName !== null ? processCheck.varName : ""}
                        error={processCheck.varName === "" || processCheck.varName === null}>
                        {vars.map((v) => {
                            return (
                                <MenuItem key={v.id} value={v.name}>
                                    {v.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            ) : null}
            {configuration.enableCheckDialogColor && (
                <>
                    <InputLabel style={{marginTop: '22px'}} htmlFor="color">{customLabel("function.color")}</InputLabel>
                    <div style={{marginTop: '10px' }}>
                        <HuePicker color={processCheck.color !== false && processCheck.color !== null ? processCheck.color : "rgba(0,0,0)"}
                            onChange={(color) => handleChangeComplete(color)} />
                    </div>
                </>
            )}
            {!maintenance ? (
                <React.Fragment>
                    <FormControl margin="normal">
                        <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="start" onChange={onChange} checked={processCheck.start} />}
                            label={customLabel("processType.check.start")} />
                    </FormControl>
                    <FormControl margin="normal">
                        <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="pause" onChange={onChange} checked={processCheck.pause} />}
                            label={customLabel("processType.check.pause")} />
                    </FormControl>
                    <FormControl margin="normal">
                        <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="restart" onChange={onChange} checked={processCheck.restart} />}
                            label={customLabel("processType.check.restart")} />
                    </FormControl>
                </React.Fragment>
            ) : null}
            <FormControl margin="normal">
                <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="close" onChange={onChange} checked={processCheck.close} />}
                    label={customLabel("processType.check.close")} />
            </FormControl>
            {!maintenance ? (
                <FormControl margin="normal">
                    <FormControlLabel labelPlacement="end" control={<Checkbox color="primary" name="onDemand" onChange={onChange} checked={processCheck.onDemand} />}
                        label={customLabel("processType.check.onDemand")} />
                </FormControl>
            ) : null}
        </form>
    )
}

export default CheckForm;