import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { customLabel } from "../utility/customLabel";
import { Container, Draggable } from "react-smooth-dnd";
import {
    Paper, Table, TableCell, TableRow, Grid, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemIcon,
    ListItemText, ListItemSecondaryAction, IconButton, TableContainer
} from "@material-ui/core";
import PopoverLegend from "../utility/PopoverLegend";
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { processStatusesSelector } from "../processStatuses/slice";
import { mesStyles } from "../utility/ultrafabStyles";
import { processTypesSelector } from "../processTypes/slice";
import arrayMove from "array-move";
import DialogConfirmAction from '../utility/DialogConfirmAction';
import ProgressBarOneLine from '../utility/ProgressBarOneLine';
import MachineEvents from './progressOdp/MachineEvents';
import UserEvents from './progressOdp/UserEvents';
import ButtonProcessStapling from '../utility/ButtonProcessStapling';
import { machineEventsSelector } from '../machineEvents/slice';
import { userEventsSelector } from '../userEvents/slice';
import { useAnchor } from '../customHooks/useAnchor';
import { configurationSelector } from '../configuration/slice';

const NewDetailsBom = ({ odp, loading, bomProcesses, flagEditBom, editBom,
    enableCloseOdpFlag, saveBomProcesses, closeOdpFlag, confirmCloseOdp, cancelCloseOdp, addProcess, removeProcess, onChangeSort,
    disableFlagEditBom }) => {
    const dispatch = useDispatch()
    const myClasses = mesStyles();
    const { processStatuses } = useSelector(processStatusesSelector);
    const { processTypes } = useSelector(processTypesSelector);
    const { machineEvents } = useSelector(machineEventsSelector);
    const { userEvents } = useSelector(userEventsSelector);
    const { configuration } = useSelector(configurationSelector)
    const [anchorProcess, { handleOpen: handleOpenProcessPopover, handleClose: handleCloseProcessPopover }, openProcessPopover] = useAnchor();
    const [anchorMachine, { handleOpen: handleOpenMachinePopover, handleClose: handleCloseMachinePopover }, openMachinePopover] = useAnchor();
    const [anchorUser, { handleOpen: handleOpenUserPopover, handleClose: handleCloseUserPopover }, openUserPopover] = useAnchor();

    const onDrop = ({ removedIndex, addedIndex }) => {
        if (odp && odp.bom && odp.bom.id !== null && !odp.bom.lockProcesses) {
            return
        }
        if (bomProcesses[addedIndex].startDate !== null) {
            return
        }
        let newArray = arrayMove(bomProcesses, removedIndex, addedIndex);
        dispatch(onChangeSort(newArray))
    };

    const renderProcesses = () => {
        if (odp && odp.processes && odp.processes.length > 0) {
            return (
                odp.processes.map((p, i) => {
                    return (
                        <React.Fragment key={p.id}>
                            <TableRow className={myClasses.backgroundGray}>
                                <TableCell style={{ wordBreak: "break-all", minWidth: "60px", maxWidth: "150px", paddingRight: "5px", paddingLeft: "5px" }}>
                                    {p.name}
                                </TableCell>
                                <TableCell align="right" style={{ paddingRight: "5px", paddingLeft: "5px" }}>
                                    <div className={myClasses.processStatusLegendDiv}>
                                        <div style={{ wordBreak: "break-all", minWidth: "60px", maxWidth: "150px", textAlign: "center" }}>
                                            {p.status.name}
                                        </div>
                                        <div className={myClasses.legendRectangle} onMouseEnter={(e) => handleOpenProcessPopover(e.currentTarget, false)} onMouseLeave={handleCloseProcessPopover}
                                            style={{ backgroundColor: p.status.backgroundColor, maxWidth: "50px" }}>
                                        </div>
                                    </div>
                                </TableCell>
                            </TableRow>
                            {p.status.id === 1 || p.status.id === 3 ?
                                <>
                                    {p.stapling.id === null ?
                                        <>
                                            <TableRow>
                                                <TableCell colSpan={2} align='center'
                                                    style={{ wordBreak: "break-all", minWidth: "60px", maxWidth: "150px", paddingRight: "5px", paddingLeft: "5px" }}>
                                                    <ProgressBarOneLine key={p.id} total={p.quantity}
                                                        current={p.validPieces !== null ? p.validPieces : p.totalPieces !== null ? p.totalPieces - p.rejectedPieces : 0}
                                                        measure={odp.article.measure} />
                                                </TableCell>
                                            </TableRow>
                                            <MachineEvents odp={odp} p={p} handleOpenPopover={handleOpenMachinePopover} handleClosePopover={handleCloseMachinePopover} />
                                            {configuration.showUserEvents ? (
                                                <UserEvents odp={odp} p={p} handleOpenPopover={handleOpenUserPopover} handleClosePopover={handleCloseUserPopover} />
                                            ) : null}
                                        </> :
                                        <>
                                            <TableRow>
                                                <TableCell colSpan={2}>
                                                    <ButtonProcessStapling message={customLabel("process.processInStapling").toUpperCase()} stapling={p.stapling} />
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    }
                                </> : null
                            }
                        </React.Fragment>
                    )
                })
            )
        } else {
            return (
                <TableRow>
                    <TableCell colSpan={2}>{customLabel("odp.noProcessesAvailable")}</TableCell>
                </TableRow>
            )
        }
    }

    return (
        <React.Fragment>
            <Paper className={myClasses.odpDetailsPaperMD}>
                <TableContainer className={myClasses.odpDetailsContainerMD}>
                    <Table size="small">
                        <tbody>
                            <TableRow>
                                <TableCell colSpan={2} align="center">
                                    <b>{customLabel(odp.maintenance ? "maintenance.processes" : "odp.bom")}</b>
                                </TableCell>
                            </TableRow>
                            {renderProcesses()}
                        </tbody>
                    </Table>
                </TableContainer>
                {anchorProcess ? (
                    <PopoverLegend name={customLabel("legend.legend").toUpperCase()} list={processStatuses} open={openProcessPopover} anchorEl={anchorProcess}
                        openPopover={handleOpenProcessPopover} closePopover={handleCloseProcessPopover}
                        anchorVertical={"bottom"} anchorHorizontal={"right"} transformVertical={"center"} transormHorizontal={"left"} />
                ) : null}
                {anchorMachine ? (
                    <PopoverLegend name={customLabel("legend.legend").toUpperCase()} list={machineEvents} open={openMachinePopover} anchorEl={anchorMachine}
                        openPopover={handleOpenMachinePopover} closePopover={handleCloseMachinePopover}
                        anchorVertical={"bottom"} anchorHorizontal={"right"} transformVertical={"center"} transormHorizontal={"left"} />
                ) : null}
                {anchorUser ? (
                    <PopoverLegend name={customLabel("legend.legend").toUpperCase()} list={userEvents} open={openUserPopover} anchorEl={anchorUser}
                        openPopover={handleOpenUserPopover} closePopover={handleCloseUserPopover}
                        anchorVertical={"bottom"} anchorHorizontal={"right"} transformVertical={"center"} transormHorizontal={"left"} />
                ) : null}
                <div className={myClasses.buttonsDivBottom}>
                    <div className={myClasses.buttonsFlexBetween}>
                        <Button color="secondary" disabled={loading || odp.endDate !== null || odp.startDate === null || (!odp.stapling && odp.processes.some((p) => p.stapling.id !== null && p.stapling.endDate === null))}
                            onClick={() => enableCloseOdpFlag()}>
                            {customLabel(!odp.maintenance ? "odp.closeOdp" : "maintenance.closeMaintenance")}
                        </Button>
                        <Button onClick={editBom} color="primary" disabled={loading || odp.lockBom}>
                            {customLabel("button.edit")}
                        </Button>
                    </div>
                </div>
            </Paper>
            {bomProcesses ? (
                <Dialog open={flagEditBom ? true : false} maxWidth={"xl"} fullWidth={true}>
                    <DialogTitle>{customLabel(!odp.maintenance ? "bom.bom" : "maintenancePhase.maintenancePhases")}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={4}>
                                <Typography variant="h6">{customLabel(!odp.maintenance ? "smartOdp.availableProcesses" : "maintenance.availablePhases")}</Typography>
                                <div>
                                    {processTypes ? (
                                        <List>
                                            {processTypes.map(p => {
                                                return (
                                                    <ListItem key={p.id}>
                                                        <ListItemText primary={p.code + " - " + p.name} />
                                                        <ListItemSecondaryAction>
                                                            <IconButton onClick={() => dispatch(addProcess(p))}><ControlPointIcon /></IconButton>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                )
                                            })}
                                        </List>
                                    ) : null}
                                </div>
                            </Grid>
                            <Grid item xs={12} md={2}>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="h6">{customLabel(!odp.maintenance ? "smartOdp.addedProcesses" : "maintenance.addedPhases")}</Typography>
                                <div>
                                    <List>
                                        <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}>
                                            {bomProcesses.map((p, i) => {
                                                if (p.startDate === undefined || p.startDate === null) {
                                                    return (
                                                        <Draggable key={p.id + "_" + i}>
                                                            <ListItem>
                                                                <ListItemText primary={p.type ? p.type.code + " - " + p.name : p.code + " - " + p.name} />
                                                                {(odp.bom.id === null || odp.bom.lockProcesses) && !odp.maintenance ? (
                                                                    <ListItemIcon className="drag-handle">
                                                                        <DragIndicatorIcon style={{ "cursor": "pointer" }} />
                                                                    </ListItemIcon>
                                                                ) : null}
                                                                <ListItemSecondaryAction>
                                                                    <IconButton onClick={() => dispatch(removeProcess(p))}><HighlightOffIcon /></IconButton>
                                                                </ListItemSecondaryAction>
                                                            </ListItem>
                                                        </Draggable>
                                                    )
                                                } else {
                                                    return (
                                                        <div key={p.id + "_" + i}>
                                                            <ListItem><ListItemText primary={p.name} />
                                                                <ListItemSecondaryAction><i>{customLabel("bom.bomProcess.alreadyStarted")}</i></ListItemSecondaryAction>
                                                            </ListItem>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </Container>
                                    </List>
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={saveBomProcesses} color="primary" disabled={loading}>
                            {customLabel("button.confirm")}
                        </Button>
                        <Button onClick={() => dispatch(disableFlagEditBom())} color="default" disabled={loading}>
                            {customLabel("button.cancel")}
                        </Button>
                    </DialogActions>
                </Dialog>
            ) : null}
            <DialogConfirmAction flag={closeOdpFlag} text={customLabel(!odp.maintenance ? "odp.confirmCloseOdp" : "maintenance.confirmCloseMaintenance")} confirm={confirmCloseOdp}
                cancel={cancelCloseOdp} disabled={loading} />
        </React.Fragment>
    )
};

export default NewDetailsBom;
