import { createSlice } from "@reduxjs/toolkit";
import _ from 'lodash';
import { SERVER_URL } from "../../constants";
import { updateInterfaceAPI as updateCustomerOrderAPI } from "../customerOrders/slice";
import { doChange } from "../dashboard/slice";
import { logout, refreshAPI } from "../main/slice";
import { showError, showSuccess } from "../notifications/slice";
import { customLabel } from "../utility/customLabel";
import { getElementListById } from "../utility/vari";
import { createVarsCreationOdp, customFilter } from "./utility";
import { ODP_HOME_TAB_DOCUMENTS } from "./utility/constants";
import {
  ERRORS_CHECKS,
  ERRORS_DR_PROCESS_EVENTS,
  ERRORS_MACHINE_EVENTS,
  ERRORS_ODP_EVENTS,
  ERRORS_PROCESS_EVENTS,
  ERRORS_PROCESS_MACHINE_VARS,
  ERRORS_STAPLING_CHECKS,
  ERRORS_STAPLING_LIST,
  ERRORS_STAPLING_MACHINE_EVENT,
  ERRORS_STAPLING_MACHINE_EVENTS,
  ERRORS_STAPLING_MACHINE_VARS,
  ERRORS_STAPLING_USER_EVENTS,
  ERRORS_STAPLING_VARS,
  ERRORS_USER_EVENTS,
  ERRORS_VARS, LOADING_CHECKS,
  LOADING_DR_PROCESS_EVENTS,
  LOADING_MACHINE_EVENTS,
  LOADING_ODP_EVENTS,
  LOADING_PROCESS_EVENTS,
  LOADING_PROCESS_MACHINE_VARS,
  LOADING_STAPLING_CHECKS,
  LOADING_STAPLING_LIST,
  LOADING_STAPLING_MACHINE_EVENT,
  LOADING_STAPLING_MACHINE_EVENTS,
  LOADING_STAPLING_MACHINE_VARS,
  LOADING_STAPLING_USER_EVENTS,
  LOADING_STAPLING_VARS,
  LOADING_USER_EVENTS,
  LOADING_VARS
} from "./utility/loadings";

export const initialState = {
  loading: false,
  reload: false,
  reloading: false, ///usato per reloadingOdpAPI del useInterval
  loadingOdpEvents: false, //usato per getOdpEventsAPI chiamato dopo ogni useInterval
  errorsOdpEvents: false,
  loadingProcessEvents: false, //getProcessEventsAPI chiamato dopo ogni getMachineEventsAPI
  errorsProcessEvents: false,
  loadingUserEvents: false,
  errorsUserEvents: false,
  reloadMachineEvents: false, //flag usato per ricaricare 
  loadingProcessMachineVars: false,
  errorsProcessMachineVars: false,
  reloadProcessEvents: false, //flag usato per ricaricare events process
  hasErrors: false,
  odps: false,
  articleList: false,
  results: 0,
  editmode: false,
  odp: false,
  odpedit: false,
  deleting: false,
  isCreated: false,
  isDeleted: false,
  process: false,
  events: false,
  colors: false,
  totEvents: false,
  totEventsKpi: false,
  currentProcessTotEvents: false,
  currentProcessTotEventsKpi: false,
  currentProcessEvents: false,
  currentProcessKpiEvents: false,
  currentProcessColors: false,
  varList: false,
  supplierFlag: false, //serve per mostrare il dialog scegli fornitore per avviare la lavorazione
  currentProcess: false, //lavorazione corrente nel odp
  currentVar: false, //valore della variabile scelta per l'inserimento
  choosenVar: false,
  listVarFlag: false, //fase 1 inserimento variaible
  insertVarFlag: false, //fase 2 inserimento variabile
  currentMachineEvents: false, //elenco eventi macchina relativi al process selezionato
  currentVars: false, //elenco vars relative al process selezionato
  currentChecks: false, //elenco checks relativi al process selezionato
  newMachineEvent: false, //evento macchina scelto per la modifica
  currentUserEvents: false, //elenco eventi utenti relativi al process selezionato
  editProcess: false, //lavorazione selezionata per modifica/visualizzazione
  adminVars: false,
  bomProcesses: false, // processes temporanei per modifica distinta,
  editVar: false, // aggiunta / modifica variabile
  loadingChecks: false,
  errorsChecks: false,
  loadingVars: false,
  errorsVars: false,
  editCheck: false,
  resultsMachineEvents: false,
  processMachineVars: false,
  flagEditBom: false, //flag per il dialog di modifica della distinta
  staplingList: false, //lista delle lavorazioni usate nella pinzatura
  loadingStaplingList: false,
  errorsStaplingList: false,
  loadingMachineEvents: false,//getMachineEventsAPI chiamato dopo ogni useInterval
  errorsMachineEvents: false,
  staplingMachineEvent: false,
  loadingStaplingMachineEvent: false,
  errorsStaplingMachineEvent: false,
  staplingVars: false,
  loadingStaplingVars: false,
  errorsStaplingVars: false,
  staplingChecks: false,
  loadingStaplingChecks: false,
  errorsStaplingChecks: false,
  staplingMachineVars: false,
  loadingStaplingMachineVars: false,
  errorsStaplingMachinevars: false,
  loadingStaplingUserEvents: false,
  errorsStaplingUserEvents: false,
  staplingUserEvents: false,
  barEvents: false,
  barKpiEvents: false,
  tabIndex: 0,
  filter: false,
  currentProcessBarEvents: false,
  currentProcessBarKpiEvents: false,
  dailyProd: false,
  loadingDrProcessEvents: false,
  errorsDrProcessEvents: false,
  drProcessEvents: false,
  reloadDrProcessEvents: false,
  resultsDrProcessEvents: false,
  loadingStaplingMachineEvents: false,
  errorsStaplingMachineEvents: false,
  resultsStaplingMachineEvents: false,
  reloadStaplingMachineEvents: false,
  currentStaplingMachineEvents: false,
  crossingTime: false,
  components: false,
  staplingComponents: false,
  lotInfo: false,
  causalizeVar: false,
  causalizationObj: false,
};

const odpSlice = createSlice({
  name: "odps",
  initialState,
  reducers: {
    resetState: (state) => {
      if (!state.filter) {
        Object.assign(state, initialState);
      } else {
        let tempState = {};
        let currentFilter = { ...state.filter }
        Object.assign(tempState, initialState);
        tempState.filter = { ...currentFilter }
        Object.assign(state, tempState);
      }
    },
    gettingList: (state) => {
      state.loading = true;
      state.reload = false;
      state.hasErrors = false;
    },
    getListSuccess: (state, { payload }) => {
      state.odps = payload[1];
      state.results = payload[0].total_entries;
      state.loading = false;
    },
    enableLoading: (state) => {
      state.loading = true;
      state.hasErrors = false;
    },
    setReload: (state) => {
      state.reload = true;
    },
    disableLoading: (state) => {
      state.loading = false;
    },
    enableReloading: (state) => {
      state.reloading = true;
      state.hasErrors = false;
    },
    updatingProcessMeasureTools: (state) => {
      state.reloading = true;
      state.hasErrors = false;
    },
    updatingProcessMeasureToolsSuccess: (state) => {
      state.loading = false;
      state.reload = true;
    },
    disableReloading: (state) => {
      state.reloading = false;
    },
    reset: (state) => {
      state.odp = false;
      state.odpedit = false;
      state.editmode = false;
      state.isDeleted = false;
      state.isCreated = false;
      state.kpiEvents = false;
      state.totEvents = false;
      state.events = false;
      state.colors = false;
      state.currentProcess = false;
      state.editProcess = false;
      state.currentMachineEvents = false;
      state.currentUserEvents = false;
      state.currentChecks = false;
      state.currentVars = false;
      state.processMachineVars = false;
      state.adminVars = false;
      state.staplingList = false;
      state.staplingMachineVars = false;
      state.staplingVars = false;
      state.staplingChecks = false;
      state.staplingMachineEvent = false;
      state.staplingUserEvents = false;
    },
    getting: (state) => {
      state.odp = false;
      state.events = false;
      state.colors = false;
      state.totEvents = false;
      state.isCreated = false;
      state.isDeleted = false;
      state.loading = true;
      state.editmode = false;
      state.hasErrors = false;
    },
    getSuccess: (state, { payload }) => {
      state.odp = payload;
      state.loading = false;
      state.tabIndex = 0
    },
    create: (state) => {
      let newodp = {
        id: "new", customerOrder: { id: '', code: '', article: { id: '' } }, customer: { id: "", name: "", code: "" }, code: "", extCode: "", article: { id: "", name: "", code: "" },
        bom: { id: "", name: "", code: "" }, quantity: "0", deliveryDate: new Date().getTime(), toBeProcessed: true, maintenance: false, lockProcesses: false,
        note: "", note2: "", note3: "", startDate: null, endDate: null, priority: 9999999
      };
      state.odp = newodp;
      state.odpedit = newodp;
      state.editmode = true;
    },
    createSuccess: (state, { payload }) => {
      state.odp = payload;
      state.odpedit = false;
      state.loading = false;
      state.isCreated = true;
      state.editmode = false;
      state.adminVars = false;
    },
    cancelIsCreated: (state) => {
      state.isCreated = false;
    },
    resetEdit: (state) => {
      state.editmode = true;
      state.odpedit = _.cloneDeep(state.odp);
      const date = new Date(state.odpedit.deliveryDate).getTime();
      state.odpedit.deliveryDate = date;
      state.loading = false;
      state.hasErrors = true;
    },
    edit: (state) => {
      state.editmode = true;
      state.odpedit = _.cloneDeep(state.odp);
      const date = new Date(state.odpedit.deliveryDate).getTime();
      state.odpedit.deliveryDate = date;
    },
    cancelEdit: (state) => {
      state.editmode = false;
      state.odpedit = false;
    },
    //crea commessa da ordine cliente inizializza dati odp
    presetOdp: (state, { payload }) => {
      let u = state.odpedit;
      u["customerOrder"] = payload.customerOrder;
      u["customer"] = payload.customerOrder.customer;
      u["article"] = { id: "", name: "", code: "" };
      u["coArticle"] = false;
      u["lockProcesses"] = false;
      u["bom"] = { id: "", name: "", code: "" };
      if (payload.customerOrder.article.id !== null) {
        if (getElementListById(payload.articles, payload.customerOrder.article.id)) {
          u["article"] = getElementListById(payload.articles, payload.customerOrder.article.id);
          u["coArticle"] = true;
        }
        if (payload.customerOrder.article.bom.id !== null) {
          if (getElementListById(payload.boms, payload.customerOrder.article.bom.id)) {
            u["bom"] = getElementListById(payload.boms, payload.customerOrder.article.bom.id);
            u["lockProcesses"] = u["bom"].lockProcesses;
          }
        }
      }
      if (payload.bom) {
        state.adminVars = createVarsCreationOdp(payload.bom, 0);
      }
      state.odpedit = u;
    },
    change: (state, { payload }) => {
      let u = state.odpedit;
      if (payload.name === "bom.id") {
        let lista = payload.boms.slice();
        let indexOfBoms = lista.findIndex((l) => {
          return l.id === payload.value
        });
        u["bom"] = lista[indexOfBoms];
        u["lockProcesses"] = lista[indexOfBoms].lockProcesses;
      } else if (payload.name === "article.id") {
        let lista = payload.articles.slice();
        let indexOfArticles = lista.findIndex((l) => {
          return l.id === payload.value
        })
        u["article"] = lista[indexOfArticles]
        if (lista[indexOfArticles].bom.id !== null) {
          let lista3 = payload.boms.slice();
          let indexOfBoms = lista3.findIndex((l) => {
            return l.id === lista[indexOfArticles].bom.id
          });
          u["bom"] = lista3[indexOfBoms];
          u["lockProcesses"] = lista3[indexOfBoms].lockProcesses;
        } else {
          u["bom"] = { id: "" }
        }
      } else if (payload.name === "quantity") {
        u["quantity"] = payload.value;
      }
      else {
        u[payload.name] = payload.value;
      }
      state.odpedit = u;
    },
    createVarsOdp: (state, { payload }) => {
      let currentQuantity = 0;
      if (state.odpedit.quantity !== "") {
        currentQuantity = state.odpedit.quantity;
      }
      state.adminVars = createVarsCreationOdp(payload, currentQuantity);
    },
    //mette a false gli autocomplete
    changeObject: (state, { payload }) => {
      let u = state.odpedit;
      if (payload.name === "bom.id") {
        u["bom"] = { id: "" }
        u["lockProcesses"] = false;
        state.adminVars = false;
      } else if (payload.name === "article.id") {
        u["article"] = { id: "", name: "" };
        u["bom"] = { id: "" }
        u["lockProcesses"] = false;
        state.adminVars = false;
      }
      state.odpedit = u;
    },
    updateSuccess: (state, { payload }) => {
      state.odp = payload;
      state.loading = false;
      state.hasErrors = false;
      state.editmode = false;
      //aggiorno current process e edit process
      const processes = payload.processes.slice();
      const currentProcessIndex = payload.currentProcess;
      if (currentProcessIndex !== null && currentProcessIndex >= 0) {
        state.currentProcess = processes[currentProcessIndex];
        state.editProcess = processes[currentProcessIndex];
      } else {
        state.currentProcess = false;
        state.editProcess = false;
      }
    },
    confirmDelete: (state) => {
      state.deleting = !state.deleting;
    },
    deleting: (state) => {
      state.deleting = false;
      state.loading = true;
      state.hasErrors = false;
    },
    deleteSuccess: (state) => {
      state.loading = false;
      state.isDeleted = true;
    },
    //aggiorna le lavorazioni al interno della commessa periodicamente
    updateOdp: (state, { payload }) => {
      state.reloading = false;
      state.reload = false;
      state.odp = payload;
      if (state.currentProcess) {
        let oldId = state.currentProcess.id;
        const indexOfProcess = state.odp.processes.findIndex((i) => {
          return i.id.toString() === oldId.toString();
        });
        if (indexOfProcess !== -1) {
          state.currentProcess = state.odp.processes[indexOfProcess];
        }
      }
      if (state.editProcess) {
        let oldId = state.editProcess.id;
        const indexOfProcess = state.odp.processes.findIndex((i) => {
          return i.id.toString() === oldId.toString();
        });
        if (indexOfProcess !== -1) {
          state.editProcess = state.odp.processes[indexOfProcess];
        }
      }
    },
    //chiamato dopo l'aggiornamente della distinta, non messo reload true in quanto cambiando l'odp vengono fatte le chiamate nel useEffect
    updateOdpBom: (state, { payload }) => {
      state.loading = false;
      state.odp = payload;
      let indexCurrentProcess = payload.currentProcess;
      if (indexCurrentProcess && indexCurrentProcess !== null) {
        state.currentProcess = payload.processes[indexCurrentProcess]
        state.editProcess = payload.processes[indexCurrentProcess];
      } else {
        state.currentProcess = false;
        state.editProcess = false;
      }
    },
    selectProcess: (state, { payload }) => {
      let selectedProcess = _.cloneDeep(payload);
      if (payload && payload.cycleTime !== null) {
        let cycleTime = payload.cycleTime.cycleTime;
        selectedProcess["cycleTimeEdit"] = cycleTime;
      }
      state.process = selectedProcess;
    },
    onChangeProcess: (state, { payload }) => {
      let process = state.process
      if (payload.name === "workcentre") {
        process["machine"] = { id: null }
      }
      if (payload.name === "machine" || payload.name === "workcentre") {
        process[payload.name] = { 'id': payload.value }
      } else {
        process[payload.name] = payload.value
      }
      state.process = process;
    },
    gettingProcess: (state) => {
      state.process = false;
      state.loading = true;
      state.hasErrors = false;
    },
    //aggiorna il process dopo la modifica dei suoi dati,
    //una volta aggiornato il process con i pulsanti (avvia ferma pausa riapri ripristina) imposta reload a true per ricaricare l'odp
    updatedProcess: (state) => {
      state.reload = true;
      state.loading = false;
    },
    //una volta aggiornata pinzatura con pulsanti riapri ripristina imposta reload a true per ricaricare l'odp
    updatedStapling: (state) => {
      state.loading = false;
      state.reload = true;
    },
    //se uno dei tasti di azioni rapide da errore fa il reload, 
    //ad esempio nel caso la lavorazione sia già stata messa in quello stato lato interfaccia
    enableProcessErrors: (state) => {
      state.loading = false;
      state.hasErrors = true;
      state.reload = true;
    },
    enableErrors: (state) => {
      state.hasErrors = true;
      state.loading = false;
      state.reloading = false;
      state.reloadMachineEvents = false;
      state.reloadProcessEvents = false;
      state.reload = false;
    },
    loadOdpEventsSuccess: (state, { payload }) => {
      let events = []
      let kpiEvents = []
      let barEvents = []
      let barKpiEvents = []
      let colors = {}
      let barData = {}
      let barKpiData = {}
      for (let e in payload.me) {
        let mt = payload.me[e]
        colors[mt.name] = mt.backgroundColor
      }

      for (let x in payload.data["events"]) {
        let me = payload.data["events"][x]
        for (let e in payload.me) {
          let mt = payload.me[e]
          if (mt.name === me[1]) {
            let v = 0
            let vKPI = 0
            if (payload.data.tot[0] !== null) {
              v = me[0] / payload.data.tot[0] * 100
            }
            if (payload.data.totKPI[0] !== null) {
              vKPI = me[0] / payload.data.totKPI[0] * 100
            }
            events.push({ id: mt.name, label: mt.name, color: mt.backgroundColor, value: v.toFixed(2), svalue: me[0] })
            barData[mt.name] = Number(v.toFixed(2))
            if (!mt.excludeFromKPI) {
              kpiEvents.push({ id: mt.name, label: mt.name, color: mt.backgroundColor, value: vKPI.toFixed(2), svalue: me[0] })
              barKpiData[mt.name] = Number(vKPI.toFixed(2))
            }
          }
        }
      }
      barEvents.push(barData)
      barKpiEvents.push(barKpiData)
      state.totEvents = payload.data.tot[0]
      state.totEventsKpi = payload.data.totKPI[0]
      state.crossingTime = payload.data.attraversamento
      state.events = events
      state.kpiEvents = kpiEvents
      state.barEvents = barEvents
      state.barKpiEvents = barKpiEvents
      state.colors = colors
      state.loadingOdpEvents = false;
    },
    setVarList: (state, { payload }) => {
      state.varList = [...payload];
      state.loading = false;
    },
    unSetVarList: (state) => {
      state.varList = false;
    },
    setSupplierFlag: (state) => {
      state.supplierFlag = true;
    },
    unSetSupplierFlag: (state) => {
      state.supplierFlag = false;
    },
    setCurrentProcess: (state, { payload }) => {
      const processes = payload.processes.slice();
      const currentProcessId = state.odp.current.id
      let indexCurrentProcess = processes.findIndex((p) => {
        return p.id.toString() === currentProcessId.toString();
      });
      if (indexCurrentProcess !== -1) {
        state.currentProcess = processes[indexCurrentProcess];
      }
    },
    unSetCurrentProcess: (state) => {
      state.currentProcess = false;
      //state.currentMachineEvents = false;
      //state.currentUserEvents = false;
    },
    setReloadMachineEvents: (state) => {
      state.reloadMachineEvents = true;
    },
    setReloadProcessEvents: (state) => {
      state.reloadProcessEvents = true;
    },
    changeCurrentVar: (state, { payload }) => {
      state.currentVar = payload.value;
    },
    enableVarFlag: (state) => {
      state.listVarFlag = true;
    },
    disableVarFlag: (state) => {
      state.listVarFlag = false;
    },
    enableInsertVarFlag: (state, { payload }) => {
      state.insertVarFlag = true;
      state.listVarFlag = false;
      state.currentVar = null;
      state.causalizeVar = payload.causalize
      if(state.causalizeVar){
          state.causalizationObj = []
      }
      state.choosenVar = payload.choosenVar;
    },
    disableInsertVarFlag: (state) => {
      state.insertVarFlag = false;
      state.listVarFlag = true;
      state.currentVar = false;
      state.choosenVar = false;
      state.causalizationObj = false;
    },
    varInsertedSuccess: (state) => {
      state.loading = false;
      state.currentVar = false;
      state.insertVarFlag = false;
      state.listVarFlag = true;
      state.reload = true;
      state.choosenVar = false;
    },
    enableVarErrors: (state) => {
      state.hasErrors = true;
      state.loading = false;
      state.insertVarFlag = false;
      state.listVarFlag = true;
      state.currentVar = false;
      state.reload = true;
      state.choosenVar = false;
      state.causalizationObj = false;
    },
    setCurrentUserEvents: (state, { payload }) => {
      state.loadingUserEvents = false;
      if (state.currentUserEvents === false) {
        state.currentUserEvents = {}
      }
      if (payload && payload.data.userEvents && payload.data.userEvents.length > 0) {
        state.currentUserEvents[payload.process.id] = [...payload.data.userEvents];
      } else {
        state.currentUserEvents[payload.process.id] = [];
      }
    },
    setCurrentMachineEvents: (state, { payload }) => {
      state.loadingMachineEvents = false;
      state.reloadMachineEvents = false;
      state.resultsMachineEvents = payload[0].total_entries;
      if (payload[1] && payload[1].length > 0) {
        state.currentMachineEvents = payload[1].slice();
      } else {
        state.currentMachineEvents = [];
      }
    },
    setProcessEvents: (state, { payload }) => {
      let events = []
      let kpiEvents = []
      let colors = {}
      let barEvents = []
      let barKpiEvents = []
      let barData = {}
      let barKpiData = {}
      //let todayProd = payload.data.todayProd
      let dailyProd = payload.data.dailyProd

      for (let e in payload.me) {
        let mt = payload.me[e]
        colors[mt.name] = mt.backgroundColor
      }

      for (let x in payload.data["events"]) {
        let me = payload.data["events"][x]
        for (let e in payload.me) {
          let mt = payload.me[e]
          if (mt.name === me[1]) {
            let v = 0
            let vKPI = 0
            if (payload.data.tot[0] !== null) {
              v = me[0] / payload.data.tot[0] * 100
            }
            if (payload.data.totKPI[0] !== null) {
              vKPI = me[0] / payload.data.totKPI[0] * 100
            }
            events.push({ id: mt.name, label: mt.name, color: mt.backgroundColor, value: v.toFixed(2), svalue: me[0] })
            barData[mt.name] = Number(v.toFixed(2))
            if (!mt.excludeFromKPI) {
              kpiEvents.push({ id: mt.name, label: mt.name, color: mt.backgroundColor, value: vKPI.toFixed(2), svalue: me[0] })
              barKpiData[mt.name] = Number(vKPI.toFixed(2))
            }
          }
        }
      }

      barEvents.push(barData)
      barKpiEvents.push(barKpiData)
      /*       if (todayProd !== null) {
              dailyProd.unshift(todayProd)
            } */
      state.dailyProd = dailyProd
      state.currentProcessBarEvents = barEvents
      state.currentProcessBarKpiEvents = barKpiEvents
      state.currentProcessTotEvents = payload.data.tot[0]
      state.currentProcessTotEventsKpi = payload.data.totKPI[0]
      state.currentProcessEvents = events
      state.currentProcessKpiEvents = kpiEvents
      state.currentProcessColors = colors
      state.reloadProcessEvents = false
      state.loadingProcessEvents = false;
    },
    setCurrentVars: (state, { payload }) => {
      payload.forEach(v => {
        if (v.type === "number") {
          v.value = Number(v.value)
        }
      })
      state.loadingVars = false;
      state.currentVars = payload;
    },
    setCurrentChecks: (state, { payload }) => {
      state.loadingChecks = false;
      state.currentChecks = payload;
    },
    setNewMachineEvent: (state, { payload }) => {
      let tempMachineEvent = _.cloneDeep(payload);
      let startDate = null;
      let endDate = null;
      if (payload.startDate !== null) {
        startDate = new Date(payload.startDate).getTime();
      }
      if (payload.endDate !== null) {
        endDate = new Date(payload.endDate).getTime();
      }
      tempMachineEvent.startDate = startDate;
      tempMachineEvent.endDate = endDate;
      state.newMachineEvent = tempMachineEvent;
    },
    unsetNewMachineEvent: (state) => {
      state.newMachineEvent = false;
    },
    changeNewMachineEvent: (state, { payload }) => {
      let u = state.newMachineEvent;
      if (payload.name === "type.id") {
        let lista = payload.machineEvents.slice();
        let indexOfM = lista.findIndex((l) => {
          return l.id === payload.value;
        });
        u["type"] = lista[indexOfM];
      } else {
        u[payload.name] = payload.value
      }
      state.newMachineEvent = u;
    },
    resetObject: (state, { payload }) => {
      let u = state.newMachineEvent;
      if (payload.name === "type.id") {
        u["type"] = { id: "" }
      }
      state.newMachineEvent = u;
    },
    updatedMachineEvent: (state) => {
      state.loading = false;
      state.reload = true;
      state.newMachineEvent = false;
    },
    notUpdatedMachineEvent: (state) => {
      state.loading = false;
      state.hasErrors = true;
      state.newMachineEvent = false;
    },
    setEditProcess: (state, { payload }) => {
      const processes = payload.processes.slice();
      const currentProcessId = payload.currentProcessId;
      let indexCurrentProcess = processes.findIndex((p) => {
        return p.id.toString() === currentProcessId.toString();
      });
      if (indexCurrentProcess !== -1) {
        state.editProcess = processes[indexCurrentProcess];
      }
    },
    unSetEditProcess: (state) => {
      state.editProcess = false;
    },
    changeAdminVar: (state, { payload }) => {
      const indexVar = state.adminVars.findIndex((v) => {
        return v.tempId.toString() === payload.tempId.toString();
      });
      if (indexVar !== -1) {
        state.adminVars[indexVar].value = payload.value;
      }
    },
    onChangeSort: (state, { payload }) => {
      state.bomProcesses = [...payload];
    },
    addProcess: (state, { payload }) => {
      let item = _.cloneDeep(payload)
      item["sortOrder"] = state.bomProcesses.length
      state.bomProcesses.push(item);
    },
    removeProcess: (state, { payload }) => {
      let bomProcesses = _.cloneDeep(state.bomProcesses)
      let index = bomProcesses.findIndex(v => {
        return v.sortOrder === payload.sortOrder;
      });
      state.bomProcesses = [...bomProcesses.filter((_, i) => i !== index)];
    },
    setBomProcesses: (state, { payload }) => {
      state.bomProcesses = payload;
    },
    resetVar: (state) => {
      state.editVar = false
    },
    setVar: (state, { payload }) => {
      state.editVar = payload;
    },
    addVar: (state) => {
      state.editVar = { "id": "new", "type": "", multiple: false, name: "", incremental: false, operatorEdit: false, adminEdit: false, editable: false, serial: false, operatorView: false, measure: "", askArticle: false, resettable: true, askConfirmation: false }
    },
    changeVar: (state, { payload }) => {
      let c = state.editVar;
      c[payload.name] = payload.value;
      state.editVar = c;
    },
    updatedProcessVar: (state, { payload }) => {
      state.editVar = false
      state.loading = false;
      //state.causalizationObj = false;
      state.insertVarFlag = false;
      state.listVarFlag = true;
      state.reload = true;
      let currentVars = _.cloneDeep(state.currentVars)
      let index = currentVars.findIndex(v => {
        return v.id.toString() === payload.id.toString();
      });
      if (index !== -1) {
        payload["value"] = payload.type === "number" ? Number(payload.value) : payload.value
        currentVars[index] = payload
      }
      else {
        currentVars.push(payload);
      }
      state.currentVars = currentVars;
    },
    addedProcessVar: (state, { payload }) => {
      state.editVar = false
      state.loading = false;
      if (payload.serial === undefined) {
        state.currentVars = payload;
      } else {
        let currentVars = _.cloneDeep(state.currentVars)
        currentVars.push(payload);
        state.currentVars = currentVars;
      }
    },
    setCheck: (state, { payload }) => {
      state.editCheck = payload;
    },
    resetCheck: (state) => {
      state.editCheck = false;
    },
    addCheck: (state) => {
      state.editCheck = {
        "id": "new", "type": "", start: false, close: false, restart: false, pause: false, onDemand: false, name: "",
        varName: "", color: ""
      }
    },
    changeCheck: (state, { payload }) => {
      let c = state.editCheck;
      if (payload.name === "type" && payload.value !== "var") {
        c["varName"] = "";
      }
      c[payload.name] = payload.value;
      state.editCheck = c;
    },
    updatedProcessCheck: (state, { payload }) => {
      state.editCheck = false
      state.loading = false;
      let currentChecks = _.cloneDeep(state.currentChecks)
      let index = currentChecks.findIndex(c => {
        return c.id.toString() === payload.id.toString();
      });
      currentChecks[index] = payload;
      state.currentChecks = currentChecks;
    },
    addedProcessCheck: (state, { payload }) => {
      state.editCheck = false
      state.loading = false;
      let currentChecks = _.cloneDeep(state.currentChecks)
      currentChecks.push(payload);
      state.currentChecks = currentChecks;
    },
    fillProcessMachineVars: (state, { payload }) => {
      state.loadingProcessMachineVars = false;
      state.processMachineVars = payload;
    },
    fillStaplingMachineVars: (state, { payload }) => {
      state.loadingStaplingMachineVars = false;
      state.staplingMachineVars = payload;
    },
    enableFlagEditBom: (state) => {
      state.flagEditBom = true;
    },
    disableFlagEditBom: (state) => {
      state.flagEditBom = false;
    },
    fillStaplingList: (state, { payload }) => {
      state.loadingStaplingList = false;
      state.errorsStaplingList = false;
      state.staplingList = payload;
    },
    fillStaplingVars: (state, { payload }) => {
      state.loadingStaplingVars = false
      state.staplingVars = payload;
    },
    fillStaplingChecks: (state, { payload }) => {
      state.loadingStaplingChecks = false;
      state.staplingChecks = payload;
    },
    fillStaplingUserEvents: (state, { payload }) => {
      state.loadingStaplingUserEvents = false;
      state.staplingUserEvents = payload;
    },
    enableCustomLoading: (state, { payload }) => {
      state[payload.loading] = true;
      state[payload.errors] = false;
    },
    enableCustomErrors: (state, { payload }) => {
      state[payload.loading] = false;
      state[payload.errors] = true;
    },
    fillStaplingMachineEvent: (state, { payload }) => {
      state.loadingStaplingMachineEvent = false;
      state.staplingMachineEvent = payload;
    },
    changeTabIndex: (state, { payload }) => {
      state.tabIndex = payload;
      if (payload === 0 && state.odp && state.odp.id !== "new") {
        state.reload = true;
      }
    },
    changeFilter: (state, { payload }) => {
      state.filter[payload.name] = payload.value;
    },
    /**
     * 
     * @param {*} state 
     * @param {boolean} payload showFilter 
     */
    initFilter: (state, { payload }) => {
      let tempFilter = { ...customFilter };
      tempFilter["showFilter"] = payload;
      state.filter = { ...tempFilter };
    },
    setDrProcessEvents: (state, { payload }) => {
      state.loadingDrProcessEvents = false;
      state.reloadDrProcessEvents = false;
      state.resultsDrProcessEvents = payload[0].total_entries;
      if (payload[1] && payload[1].length > 0) {
        state.drProcessEvents = payload[1].slice();
      } else {
        state.drProcessEvents = [];
      }
    },
    setReloadDrProcessEvents: (state) => {
      state.reloadDrProcessEvents = true;
    },
    setCurrentStaplingMachineEvents: (state, { payload }) => {
      state.loadingStaplingMachineEvents = false;
      state.reloadStaplingMachineEvents = false;
      state.resultsStaplingMachineEvents = payload[0].total_entries;
      if (payload[1] && payload[1].length > 0) {
        state.currentStaplingMachineEvents = payload[1].slice();
      } else {
        state.currentStaplingMachineEvents = [];
      }
    },
    setReloadStaplingMachineEvents: (state) => {
      state.reloadStaplingMachineEvents = true;
    },
    updatedStaplingMachineEvent: (state) => {
      state.loading = false;
      state.reload = true;
      state.newMachineEvent = false;
    },
    notUpdatedStaplingMachineEvent: (state) => {
      state.loading = false;
      state.hasErrors = true;
      state.newMachineEvent = false;
    },
    fillComponents: (state, { payload }) => {
      state.components = payload.data;
    },
    fillStaplingComponents: (state, { payload }) => {
      state.staplingComponents = payload;
    },
    getLotInfoSuccess: (state, { payload }) => {
        if(!state.lotInfo){
            state.lotInfo = {}
        }
        state.lotInfo[payload.lot] = payload.data.quantity
    },
    addRowToCausalizationObj: (state) => {
      state.causalizationObj.push({"value":1,"reason":'',"article":false})
    },
    removeRowToCausalizationObj: (state, {payload}) => {
        let obj = _.cloneDeep(state.causalizationObj)
        obj.splice(payload,1)
        state.causalizationObj = obj
    },
    changeCausalizationObj: (state, {payload}) => {
        let obj = _.cloneDeep(state.causalizationObj)
        obj[payload.key][payload.item] = payload.value
        state.causalizationObj = obj
    },
  }
});

export const { resetState, gettingList, getListSuccess, setReload, reset, getting, getSuccess, create, createSuccess, edit, cancelEdit, change,
  changeObject, updateSuccess, confirmDelete, deleting, deleteSuccess, resetEdit, loadingodps, loadingodpsSuccess, updateOdp,
  selectProcess, onChangeProcess, updatedProcess, gettingProcess, enableErrors, loadOdpEventsSuccess, cancelIsCreated,
  enableLoading, disableLoading, enableReloading, disableReloading, setVarList, unSetVarList, setSupplierFlag, unSetSupplierFlag,
  setCurrentProcess, unSetCurrentProcess, changeCurrentVar, enableInsertVarFlag, disableInsertVarFlag, enableVarFlag, disableVarFlag, varInsertedSuccess, enableVarErrors, updatingProcessMeasureTools,
  updatingProcessMeasureToolsSuccess, enableProcessErrors, setCurrentMachineEvents, setNewMachineEvent, unsetNewMachineEvent,
  changeNewMachineEvent, resetObject, updatedMachineEvent, notUpdatedMachineEvent,
  setCurrentUserEvents, setEditProcess, unSetEditProcess, changeAdminVar,
  onChangeSort, addProcess, removeProcess, setBomProcesses, resetVar, setVar, addVar, changeVar, setCurrentChecks,
  setCurrentVars, updatedProcessVar, addedProcessVar, setCheck, resetCheck, addCheck, changeCheck,
  updatedProcessCheck, addedProcessCheck, setReloadMachineEvents, enableFlagEditBom, disableFlagEditBom, updateOdpBom,
  fillProcessMachineVars, fillStaplingList, enableCustomLoading, enableCustomErrors,
  fillStaplingMachineEvent, fillStaplingVars, fillStaplingChecks, fillStaplingMachineVars, fillStaplingUserEvents,
  setProcessEvents, setReloadProcessEvents, updatedStapling, presetOdp, createVarsOdp, changeTabIndex,
  changeFilter, initFilter, setDrProcessEvents, setReloadDrProcessEvents, setCurrentStaplingMachineEvents, setReloadStaplingMachineEvents,
  updatedStaplingMachineEvent, notUpdatedStaplingMachineEvent, fillComponents, fillStaplingComponents, getLotInfoSuccess,
  addRowToCausalizationObj, removeRowToCausalizationObj, changeCausalizationObj } = odpSlice.actions;

export const odpsSelector = (state) => state.odps;

export default odpSlice.reducer;

export function getListAPI(page, rowsPerPage, orderBy, order, customerOrder, code, customer, bom, article, extCode, maintenance, deliveryDateFrom, deliveryDateTo,
  startDateFrom, startDateTo, endDateFrom, endDateTo, toBeProcessed, status, priority, dateCreatedFrom, dateCreatedTo) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(gettingList());
    dispatch(reset());
    let url = SERVER_URL + "/api/odp?maintenance=" + maintenance + "&page=" + page + "&per_page=" + rowsPerPage + "&orderBy=" + orderBy + "&order=" + order;
    if (customerOrder !== false) {
      url = url + "&customerOrder=" + customerOrder;
    }
    if (code !== false) {
      url = url + "&code=" + code;
    }
    if (customer !== false) {
      url = url + "&customer=" + customer.id;
    }
    if (bom !== false) {
      url = url + "&bom=" + bom.id;
    }
    if (article !== false) {
      url = url + "&article=" + article.id;
    }
    if (extCode !== false) {
      url = url + "&extCode=" + extCode;
    }
    if (toBeProcessed !== false) {
      url = url + "&toBeProcessed=" + toBeProcessed.id;
    }
    if (priority !== false) {
      url = url + "&priority=" + priority;
    }
    if (status.length > 0) {
      for (let x in status) {
        url = url + "&status=" + status[x].id;
      }
    }
    if (deliveryDateFrom) {
      url = url + "&deliveryDateFrom=" + deliveryDateFrom
    }
    if (deliveryDateTo) {
      url = url + "&deliveryDateTo=" + deliveryDateTo
    }
    if (startDateFrom) {
      url = url + "&startDateFrom=" + startDateFrom
    }
    if (startDateTo) {
      url = url + "&startDateTo=" + startDateTo
    }
    if (endDateFrom) {
      url = url + "&endDateFrom=" + endDateFrom
    }
    if (endDateTo) {
      url = url + "&endDateTo=" + endDateTo
    }
    if (dateCreatedFrom) {
      url = url + "&dateCreatedFrom=" + dateCreatedFrom
    }
    if (dateCreatedTo) {
      url = url + "&dateCreatedTo=" + dateCreatedTo
    }

    const response = await fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
      },
    });
    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(getListSuccess(data));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

export function updateAPI(odp) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  let params = { odp: odp };
  return async (dispatch) => {
    dispatch(enableLoading());
    const response = await fetch(SERVER_URL + "/api/odp/" + odp.id, {
      mode: "cors",
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updateSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(resetEdit());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function createAPI(odp, adminVars, customerOrder) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(enableLoading());
    let params = { odp: odp, adminVars: adminVars };
    const response = await fetch(SERVER_URL + "/api/odp", {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });
    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(createSuccess(data));
        dispatch(showSuccess("function.operationSuccess"));
        //se creo la commessa dal ordine cliente riaggiorno l'ordine, altrimenti cambio l url in odps in quanto si verra reinderizzati a odps
        if (customerOrder) {
          dispatch(updateCustomerOrderAPI(customerOrder));
        } else {
          dispatch(doChange("/odps"));
        }
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors())
      }
      if (customerOrder) {
        dispatch(resetState());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function getAPI(id) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(getting());
    const response = await fetch(SERVER_URL + "/odp/" + id, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(getSuccess(data));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function deleteAPI(odp) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(deleting());
    const response = await fetch(SERVER_URL + "/api/odp/" + odp.id, {
      mode: "cors",
      method: "DELETE",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(deleteSuccess());
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function reloadingOdpAPI(odp, machineEvents, errorsOdpEvents) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  //let url = odp.maintenance ? "/maintenance/" : "/odp/";
  return async (dispatch) => {
    dispatch(enableReloading());
    const response = await fetch(SERVER_URL + "/odp/" + odp.id, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updateOdp(data));
        if (machineEvents && !errorsOdpEvents) {
          dispatch(getOdpEventsAPI(odp, machineEvents))
        }
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

/**
 * 
 * @param {*} odp 
 * @param {*} errorsStaplingList 
 * @param {*} errorsStaplingMachineEvent 
 * @param {*} errorsStaplingVars 
 * @param {*} errorsStaplingMachineVars 
 * @param {*} errorsStaplingChecks 
 * @param {*} machineEvents 
 * @param {*} errorsOdpEvents
 * @param {*} errorsStaplingUserEvents 
 * @returns 
 */
export function reloadingStaplingOdpAPI(odp, errorsStaplingList, errorsStaplingMachineEvent, errorsStaplingVars, errorsStaplingMachineVars, errorsStaplingChecks, machineEvents, errorsOdpEvents, errorsStaplingUserEvents, retrieveLotInfo) {
  return async dispatch => {
    dispatch(enableReloading());
    const response = await fetch(SERVER_URL + "/odp/" + odp.id, {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      }
    });
    const data = await response.json()
    if (response.status === 200) {
      dispatch(updateOdp(data));
      dispatch(getStaplingComponentsAPI(data, retrieveLotInfo))
      if (!errorsStaplingList) { dispatch(getStaplingListAPI(data)) }
      if (!errorsStaplingMachineEvent) { dispatch(getStaplingMachineEventAPI(data)) }
      if (!errorsStaplingUserEvents) { dispatch(getStaplingUserEventsAPI(data)) }
      if (!errorsStaplingVars) { dispatch(getStaplingVarsAPI(data)) }
      if (!errorsStaplingMachineVars) { dispatch(getStaplingMachineVarsAPI(data)) }
      if (!errorsStaplingChecks) { dispatch(getStaplingChecksAPI(data)) }
      if (machineEvents && !errorsOdpEvents) {
        dispatch(getOdpEventsAPI(odp, machineEvents))
      }
    } else {
      dispatch(enableErrors());
      dispatch(showError(data.message));
    }
  }
}

export function updateProcessAPI(odp, process) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  let params = { process: process };
  return async (dispatch) => {
    dispatch(gettingProcess());
    const response = await fetch(SERVER_URL + "/api/odp/" + odp.id + "/process/" + process.id, {
      mode: "cors",
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updatedProcess());
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(enableErrors());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
};

export function updateProcessNoteAPI(process) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  let params = { process: process };
  return async (dispatch) => {
    dispatch(gettingProcess());
    const response = await fetch(SERVER_URL + "/process/" + process.id + "/note/", {
      mode: "cors",
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updatedProcess());
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(enableErrors());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

export function pauseProcessAPI(odpId, processId, username, machineId) {
  return async dispatch => {
    dispatch(enableLoading());
    let params = { user: { username: username }, machine: { id: machineId } }
    const response = await fetch(SERVER_URL + "/odp/" + odpId + "/process/" + processId + "/pause/", {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(params)
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(updatedProcess());
        dispatch(showSuccess("function.operationSuccess"));
      } else {
        dispatch(enableProcessErrors());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableProcessErrors());
    }
  }
};

export function stopProcessAPI(odpId, processId, username, machineId) {
  return async dispatch => {
    dispatch(enableLoading());
    let params = { user: { username: username }, machine: { id: machineId } }
    const response = await fetch(SERVER_URL + "/odp/" + odpId + "/process/" + processId + "/stop/", {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(params)
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(updatedProcess());
        dispatch(showSuccess("function.operationSuccess"));
      } else {
        dispatch(enableProcessErrors());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableProcessErrors());
    }
  }
};
export function reopenProcessAPI(odpId, processId) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async dispatch => {
    dispatch(enableLoading());
    const response = await fetch(SERVER_URL + "/api/odp/" + odpId + "/process/" + processId + "/reopen/", {
      mode: 'cors',
      method: 'POST',
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(updatedProcess());
        dispatch(showSuccess("function.operationSuccess"));
      } else {
        dispatch(enableProcessErrors());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableProcessErrors());
    }
  }
};
export function restoreProcessAPI(odpId, processId) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async dispatch => {
    dispatch(enableLoading());
    const response = await fetch(SERVER_URL + "/api/odp/" + odpId + "/process/" + processId + "/restore/", {
      mode: 'cors',
      method: 'POST',
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(updatedProcess());
        dispatch(showSuccess("function.operationSuccess"));
      } else {
        dispatch(enableProcessErrors());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableProcessErrors());
    }
  }
};

export function startProcessAPI(odpId, processId, username, machineId) {
  return async dispatch => {
    dispatch(enableLoading());
    let params = { user: { username: username }, machine: { id: machineId } }
    const response = await fetch(SERVER_URL + "/odp/" + odpId + "/process/" + processId + "/start/", {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(params)
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(unSetSupplierFlag());
        dispatch(updatedProcess());
        dispatch(showSuccess("function.operationSuccess"));
      } else {
        dispatch(enableProcessErrors());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableProcessErrors());
    }
  }
};
export function insertVarsAPI(odpId, processId, varId, value, username, machineId) {
  return async dispatch => {
    dispatch(enableLoading());
    let params = { value: value, user: { username: username }, machine: { id: machineId } }
    const response = await fetch(SERVER_URL + "/odp/" + odpId + "/process/" + processId + "/var/" + varId, {
      mode: 'cors',
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(params)
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(varInsertedSuccess());
        dispatch(showSuccess("function.operationSuccess"));
      } else {
        dispatch(enableVarErrors());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableVarErrors());
    }
  }
};
export function getListVarsAPI(odpId, processId, variable) {
  let url = SERVER_URL + "/odp/" + odpId + "/process/" + processId + "/var/type/" + variable.varDef.id;
  if (variable.serial) {
    url = url + "?serialNo=" + variable.serialNo
  }
  return async dispatch => {
    dispatch(enableLoading());
    const response = await fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
    });

    const data = await response.json()
    try {
      if (response.status === 200) {
        dispatch(setVarList(data));
      } else {
        dispatch(enableErrors());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
};
export function getListVarsAPI2(odpId, processId, variable) {
  let url = SERVER_URL + "/odp/" + odpId + "/process/" + processId + "/var/name/" + variable.name;
  if (variable.serial) {
    url = url + "?serialNo=" + variable.serialNo
  }
  return async dispatch => {
    dispatch(enableLoading());
    const response = await fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
    });

    const data = await response.json()
    try {
      if (response.status === 200) {
        dispatch(setVarList(data));
      } else {
        dispatch(enableErrors());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
};
export function removeMeasureToolAPI(process, measureTool) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(enableLoading())
    const response = await fetch(SERVER_URL + '/api/process/' + process.id + '/measureTool/' + measureTool.id, {
      mode: 'cors',
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(updatingProcessMeasureToolsSuccess())
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}

export function addMeasureToolsAPI(process, measureTools) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(enableLoading())
    let params = { measureTools: measureTools }
    const response = await fetch(SERVER_URL + '/api/process/' + process.id + '/measureTool', {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      },
      body: JSON.stringify(params)
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(updatingProcessMeasureToolsSuccess())
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableErrors())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
}
export function getOdpEventsAPI(odp, machineEvents) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(enableCustomLoading({ loading: LOADING_ODP_EVENTS, errors: ERRORS_ODP_EVENTS }));
    const response = await fetch(SERVER_URL + "/api/odp/" + odp.id + "/events", {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      }
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(loadOdpEventsSuccess({ data: data, me: machineEvents }));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableCustomErrors({ loading: LOADING_ODP_EVENTS, errors: ERRORS_ODP_EVENTS }));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableCustomErrors({ loading: LOADING_ODP_EVENTS, errors: ERRORS_ODP_EVENTS }));
    }
  }
};
export function getProcessUserEventsAPI(odp, process) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(enableCustomLoading({ loading: LOADING_USER_EVENTS, errors: ERRORS_USER_EVENTS }))
    const response = await fetch(SERVER_URL + '/api/odp/' + odp.id + '/process/' + process.id, {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(setCurrentUserEvents({ data: data, process: process }))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableCustomErrors({ loading: LOADING_USER_EVENTS, errors: ERRORS_USER_EVENTS }))
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableCustomErrors({ loading: LOADING_USER_EVENTS, errors: ERRORS_USER_EVENTS }))
    }
  }
};
export function getProcessMachineEventsAPI(odp, process, page, rowsPerPage, orderBy, order, type, machine, user, startDateFrom, endDateFrom, startDateTo, endDateTo) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(enableCustomLoading({ loading: LOADING_MACHINE_EVENTS, errors: ERRORS_MACHINE_EVENTS }))
    let url = SERVER_URL + '/api/odp/' + odp.id + '/process/' + process.id + '/machineEvent?page=' + page + "&per_page=" + rowsPerPage + "&orderBy=" +
      orderBy + "&order=" + order;

    if (type !== false) {
      url = url + "&type=" + type.id;
    }
    if (machine !== false) {
      url = url + "&machine=" + machine.id;
    }
    if (user !== false) {
      url = url + "&user=" + user.id;
    }
    if (startDateFrom !== false) {
      url = url + "&startDateFrom=" + startDateFrom;
    }
    if (endDateFrom !== false) {
      url = url + "&endDateFrom=" + endDateFrom;
    }
    if (startDateTo !== false) {
      url = url + "&startDateTo=" + startDateTo;
    }
    if (endDateTo !== false) {
      url = url + "&endDateTo=" + endDateTo;
    }

    const response = await fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(setCurrentMachineEvents(data))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableCustomErrors({ loading: LOADING_MACHINE_EVENTS, errors: ERRORS_MACHINE_EVENTS }))
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableCustomErrors({ loading: LOADING_MACHINE_EVENTS, errors: ERRORS_MACHINE_EVENTS }))
    }
  }
}

export function getDrProcessEventsAPI(odp, process, page, rowsPerPage, orderBy, order, type, machine, startDateFrom, endDateFrom, startDateTo, endDateTo) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(enableCustomLoading({ loading: LOADING_DR_PROCESS_EVENTS, errors: ERRORS_DR_PROCESS_EVENTS }))
    let url = SERVER_URL + '/api/odp/' + odp.id + '/process/' + process.id + '/processEvent?page=' + page + "&per_page=" + rowsPerPage + "&orderBy=" +
      orderBy + "&order=" + order;

    if (type !== false) {
      url = url + "&type=" + type.id;
    }
    if (machine !== false) {
      url = url + "&machine=" + machine.id;
    }
    if (startDateFrom !== false) {
      url = url + "&startDateFrom=" + startDateFrom;
    }
    if (endDateFrom !== false) {
      url = url + "&endDateFrom=" + endDateFrom;
    }
    if (startDateTo !== false) {
      url = url + "&startDateTo=" + startDateTo;
    }
    if (endDateTo !== false) {
      url = url + "&endDateTo=" + endDateTo;
    }

    const response = await fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(setDrProcessEvents(data))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableCustomErrors({ loading: LOADING_DR_PROCESS_EVENTS, errors: ERRORS_DR_PROCESS_EVENTS }))
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableCustomErrors({ loading: LOADING_DR_PROCESS_EVENTS, errors: ERRORS_DR_PROCESS_EVENTS }))
    }
  }
}

export function getProcessEventsAPI(odp, process, machineEvents) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(enableCustomLoading({ loading: LOADING_PROCESS_EVENTS, errors: ERRORS_PROCESS_EVENTS }))
    let url = SERVER_URL + '/api/odp/' + odp.id + '/process/' + process.id + '/machineEventStats'
    const response = await fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(setProcessEvents({ data: data, me: machineEvents }));
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableCustomErrors({ loading: LOADING_PROCESS_EVENTS, errors: ERRORS_PROCESS_EVENTS }));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableCustomErrors({ loading: LOADING_PROCESS_EVENTS, errors: ERRORS_PROCESS_EVENTS }));
    }
  }
}
export function getProcessVarsAPI(odp, process) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(enableCustomLoading({ loading: LOADING_VARS, errors: ERRORS_VARS }))
    const response = await fetch(SERVER_URL + '/api/odp/' + odp.id + '/process/' + process.id + '/var', {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(setCurrentVars(data))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableCustomErrors({ loading: LOADING_VARS, errors: ERRORS_VARS }))
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableCustomErrors({ loading: LOADING_VARS, errors: ERRORS_VARS }));
    }
  }
}
export function getProcessChecksAPI(odp, process) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(enableCustomLoading({ loading: LOADING_CHECKS, errors: ERRORS_CHECKS }))
    const response = await fetch(SERVER_URL + '/api/odp/' + odp.id + '/process/' + process.id + '/check', {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(setCurrentChecks(data))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableCustomErrors({ loading: LOADING_CHECKS, errors: ERRORS_CHECKS }))
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableCustomErrors({ loading: LOADING_CHECKS, errors: ERRORS_CHECKS }))
    }
  }
}
export function updateMachineEventAPI(odp, process, machineEvent) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(enableLoading())
    let params = { machineEvent: machineEvent }

    const response = await fetch(SERVER_URL + '/api/odp/' + odp.id + '/process/' + process.id + '/machineEvent/' + machineEvent.id, {
      mode: 'cors',
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      },
      body: JSON.stringify(params)
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(updatedMachineEvent())
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(notUpdatedMachineEvent())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(notUpdatedMachineEvent());
    }
  }
}
//cancella
export function updateProcessMachineAPI(odp, process) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  let params = { process: process };
  return async (dispatch) => {
    dispatch(gettingProcess());
    const response = await fetch(SERVER_URL + "/api/odp/" + odp.id + "/process/" + process.id, {
      mode: "cors",
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updatedProcess());
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(enableErrors());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
};

//cambio struttura distinta
export function updateOdpProcessesAPI(odp, processes) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  let params = { odp: odp, processes: processes };
  return async (dispatch) => {
    dispatch(gettingProcess());
    const response = await fetch(SERVER_URL + "/api/odp/" + odp.id + "/processes", {
      mode: "cors",
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updateOdpBom(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(enableErrors());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
};

// crea variabile
export function createVar(odp, process, processVar) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  let params = { var: processVar };
  return async (dispatch) => {
    dispatch(gettingProcess());
    const response = await fetch(SERVER_URL + "/api/odp/" + odp.id + "/process/" + process.id + "/var", {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(addedProcessVar(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(enableErrors());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
};
// aggiorna variabile
export function updateVar(odp, process, processVar) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  let params = { var: processVar };
  return async (dispatch) => {
    dispatch(gettingProcess());
    const response = await fetch(SERVER_URL + "/api/odp/" + odp.id + "/process/" + process.id + "/var/" + processVar.id, {
      mode: "cors",
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updatedProcessVar(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(enableErrors());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
};
//aggiorna check
export function updateCheckAPI(odp, process, processCheck) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  let params = { check: processCheck };
  return async (dispatch) => {
    dispatch(gettingProcess());
    const response = await fetch(SERVER_URL + "/api/odp/" + odp.id + "/process/" + process.id + "/check/" + processCheck.id, {
      mode: "cors",
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updatedProcessCheck(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(enableErrors());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
};
// creo check
export function createCheckAPI(odp, process, processCheck) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  let params = { check: processCheck };
  return async (dispatch) => {
    dispatch(gettingProcess());
    const response = await fetch(SERVER_URL + "/api/odp/" + odp.id + "/process/" + process.id + "/check", {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(params),
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(addedProcessCheck(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(enableErrors());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  }
};
export function getProcessMachineVarsAPI(odp, process) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(enableCustomLoading({ loading: LOADING_PROCESS_MACHINE_VARS, errors: ERRORS_PROCESS_MACHINE_VARS }))
    const response = await fetch(SERVER_URL + '/odp/' + odp.id + '/process/' + process.id + "/machineVar", {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(fillProcessMachineVars(data))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableCustomErrors({ loading: LOADING_PROCESS_MACHINE_VARS, errors: ERRORS_PROCESS_MACHINE_VARS }))
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableCustomErrors({ loading: LOADING_PROCESS_MACHINE_VARS, errors: ERRORS_PROCESS_MACHINE_VARS }))
    }
  }
};
export function stopOdpAPI(odp) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  //let url = odp.maintenance ? "/maintenance/" : "/odp/";
  let url = SERVER_URL + "/api/odp/" + odp.id + "/stop";
  return async (dispatch) => {
    dispatch(enableReloading());
    const response = await fetch(url, {
      mode: "cors",
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updateOdp(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function getStaplingListAPI(odp) {
  return async (dispatch) => {
    dispatch(enableCustomLoading({ loading: LOADING_STAPLING_LIST, errors: ERRORS_STAPLING_LIST }));
    const response = await fetch(SERVER_URL + '/odp/' + odp.id + "/stapling/processes", {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    const data = await response.json();
    if (response.status === 200) {
      dispatch(fillStaplingList(data))
    } else {
      dispatch(showError(data.message));
      dispatch(enableCustomErrors({ loading: LOADING_STAPLING_LIST, errors: ERRORS_STAPLING_LIST }));
    }
  };
};
export function getStaplingMachineEventAPI(odp) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(enableCustomLoading({ loading: LOADING_STAPLING_MACHINE_EVENT, errors: ERRORS_STAPLING_MACHINE_EVENT }));
    const response = await fetch(SERVER_URL + '/api/odp/' + odp.id + "/stapling/machineEvent", {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        'Authorization': 'Bearer ' + access_token
      },
    });
    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(fillStaplingMachineEvent(data))
      } else {
        dispatch(showError(data.message));
        dispatch(enableCustomErrors({ loading: LOADING_STAPLING_MACHINE_EVENT, errors: ERRORS_STAPLING_MACHINE_EVENT }));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableCustomErrors({ loading: LOADING_STAPLING_MACHINE_EVENT, errors: ERRORS_STAPLING_MACHINE_EVENT }));
    }
  };
};
export function getStaplingUserEventsAPI(odp) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async (dispatch) => {
    dispatch(enableCustomLoading({ loading: LOADING_STAPLING_USER_EVENTS, errors: ERRORS_STAPLING_USER_EVENTS }));
    const response = await fetch(SERVER_URL + '/api/odp/' + odp.id + "/stapling/userEvents", {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        'Authorization': 'Bearer ' + access_token
      },
    });
    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(fillStaplingUserEvents(data))
      } else {
        dispatch(showError(data.message));
        dispatch(enableCustomErrors({ loading: LOADING_STAPLING_USER_EVENTS, errors: ERRORS_STAPLING_USER_EVENTS }));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableCustomErrors({ loading: LOADING_STAPLING_USER_EVENTS, errors: ERRORS_STAPLING_USER_EVENTS }));
    }
  };
};
export function getStaplingVarsAPI(odp) {
  return async dispatch => {
    dispatch(enableCustomLoading({ loading: LOADING_STAPLING_VARS, errors: ERRORS_STAPLING_VARS }));
    const response = await fetch(SERVER_URL + "/odp/" + odp.id + "/stapling/vars", {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      }
    });
    const data = await response.json()
    if (response.status === 200) {
      dispatch(fillStaplingVars(data));
    } else {
      dispatch(enableCustomErrors({ loading: LOADING_STAPLING_VARS, errors: ERRORS_STAPLING_VARS }));
      dispatch(showError(data.message));
    }
  }
};
export function getStaplingChecksAPI(odp) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(enableCustomLoading({ loading: LOADING_STAPLING_CHECKS, errors: ERRORS_STAPLING_CHECKS }));
    const response = await fetch(SERVER_URL + "/api/odp/" + odp.id + "/stapling/checks", {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });
    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(fillStaplingChecks(data));
      } else {
        dispatch(enableCustomErrors({ loading: LOADING_STAPLING_CHECKS, errors: ERRORS_STAPLING_CHECKS }));
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableCustomErrors({ loading: LOADING_STAPLING_CHECKS, errors: ERRORS_STAPLING_CHECKS }));
    }
  }
};
export function getStaplingMachineVarsAPI(odp) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(enableCustomLoading({ loading: LOADING_STAPLING_MACHINE_VARS, errors: ERRORS_STAPLING_MACHINE_VARS }));
    const response = await fetch(SERVER_URL + "/api/odp/" + odp.id + "/stapling/machineVar/", {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });
    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(fillStaplingMachineVars(data));
      } else {
        dispatch(enableCustomErrors({ loading: LOADING_STAPLING_MACHINE_VARS, errors: ERRORS_STAPLING_MACHINE_VARS }));
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableCustomErrors({ loading: LOADING_STAPLING_MACHINE_VARS, errors: ERRORS_STAPLING_MACHINE_VARS }));
    }
  }
};
export function deleteStaplingAPI(odp) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(deleting());
    const response = await fetch(SERVER_URL + "/api/odp/" + odp.id + "/deleteStapling", {
      mode: "cors",
      method: "DELETE",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(deleteSuccess());
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function restoreStaplingAPI(odp) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(enableLoading());
    const response = await fetch(SERVER_URL + "/api/odp/" + odp.id + "/restoreStapling", {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updatedStapling(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
export function reopenStaplingAPI(odp) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    dispatch(enableLoading());
    const response = await fetch(SERVER_URL + "/api/odp/" + odp.id + "/reopenStapling", {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(updatedStapling(data));
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}

export function doReport(id, url, template) {
  let access_token = "";
  if (localStorage.getItem("bishop_current_user") != null) {
    access_token = JSON.parse(localStorage.getItem("bishop_current_user"))
      .access_token;
  }
  return async (dispatch) => {
    const response = await fetch(SERVER_URL + "/odp/" + id, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {

        let bodyJSON = { "template": template, "odp": data }

        const response = await fetch(url, {
          mode: "cors",
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: "Bearer " + access_token,
          },
          body: JSON.stringify(bodyJSON)
        });
        try {
          const data = await response.text();
          if (response.status === 200) {
          } else if (response.status === 403) {
            dispatch(logout());
          } else {
            dispatch(showError(data.message));
            dispatch(enableErrors());
          }
        } catch (e) {
          if (response.status === 401) {
            dispatch(refreshAPI())
            dispatch(showError(customLabel("function.sessionExpired")));
          } else {
            dispatch(showError(e.message));
          }
          dispatch(enableErrors());
        }
      } else if (response.status === 403) {
        dispatch(logout());
      } else {
        dispatch(showError(data.message));
        dispatch(enableErrors());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        // dispatch(showError(e.message));
      }
      dispatch(enableErrors());
    }
  };
}
/**
 * 
 * @param {*} odp 
 * @param {*} url 
 * @param {*} template 
 * @returns 
 */
export function getExtendedOdpAPI(odp, url, template) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async (dispatch) => {
    dispatch(enableLoading());
    const response = await fetch(SERVER_URL + "/api/odp/extended/" + odp.id, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(createReportAPI(url, template, data))
      } else {
        dispatch(showError(data.message));
        dispatch(disableLoading());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(disableLoading());
    }
  };
};
export function createReportAPI(url, template, extendedOdp) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  let bodyJSON = { "template": template, "odp": extendedOdp }
  return async (dispatch) => {
    const response = await fetch(url, {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(bodyJSON)
    });

    try {
      const data = await response.json();
      if (response.status === 200) {
        dispatch(changeTabIndex(ODP_HOME_TAB_DOCUMENTS))
        dispatch(disableLoading())
        dispatch(showSuccess("function.operationSuccess"));
      } else {
        dispatch(showError(data.message));
        dispatch(disableLoading());
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(disableLoading());
    }
  };
};
export function getStaplingMachineEventsAPI(odp, page, rowsPerPage, orderBy, order, type, machine, user, startDateFrom, endDateFrom, startDateTo, endDateTo) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(enableCustomLoading({ loading: LOADING_STAPLING_MACHINE_EVENTS, errors: ERRORS_STAPLING_MACHINE_EVENTS }))
    let url = SERVER_URL + '/api/odp/' + odp.id + '/stapling/machineEvents?page=' + page + "&per_page=" + rowsPerPage + "&orderBy=" +
      orderBy + "&order=" + order;

    if (type !== false) {
      url = url + "&type=" + type.id;
    }
    if (machine !== false) {
      url = url + "&machine=" + machine.id;
    }
    if (user !== false) {
      url = url + "&user=" + user.id;
    }
    if (startDateFrom !== false) {
      url = url + "&startDateFrom=" + startDateFrom;
    }
    if (endDateFrom !== false) {
      url = url + "&endDateFrom=" + endDateFrom;
    }
    if (startDateTo !== false) {
      url = url + "&startDateTo=" + startDateTo;
    }
    if (endDateTo !== false) {
      url = url + "&endDateTo=" + endDateTo;
    }

    const response = await fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      }
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(setCurrentStaplingMachineEvents(data))
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(enableCustomErrors({ loading: LOADING_STAPLING_MACHINE_EVENTS, errors: ERRORS_STAPLING_MACHINE_EVENTS }))
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableCustomErrors({ loading: LOADING_STAPLING_MACHINE_EVENTS, errors: ERRORS_STAPLING_MACHINE_EVENTS }))
    }
  }
}
export function updateStaplingMachineEventAPI(odp, machineEvent) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(enableLoading())
    let params = { machineEvent: machineEvent }
    const response = await fetch(SERVER_URL + '/api/odp/' + odp.id + '/stapling/machineEvent/' + machineEvent.id, {
      mode: 'cors',
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      },
      body: JSON.stringify(params)
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(updatedStaplingMachineEvent())
        dispatch(showSuccess("function.operationSuccess"));
      } else if (response.status === 403) {
        dispatch(logout())
      } else {
        dispatch(showError(data.message))
        dispatch(notUpdatedStaplingMachineEvent())
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(notUpdatedStaplingMachineEvent());
    }
  }
}
export function pauseStaplingAPI(odp, machine, user) {
  let access_token = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
  }
  return async dispatch => {
    dispatch(enableLoading());
    let params = { machine: { id: machine }, user: { id: user }, unmountStapling: false }
    const response = await fetch(SERVER_URL + "/odp/" + odp.id + "/pauseStapling", {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + access_token
      },
      body: JSON.stringify(params)
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(updatedStapling());
        dispatch(showSuccess("function.operationSuccess"));
      } else {
        dispatch(enableProcessErrors());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableProcessErrors());
    }
  }
};
export function stopStaplingAPI(odp, machine, user) {
  let username = ""
  if (localStorage.getItem('bishop_current_user') != null) {
    username = JSON.parse(localStorage.getItem('bishop_current_user')).username
  }
  return async dispatch => {
    dispatch(enableLoading());
    let params = { machine: { id: machine }, user: { username: username } }
    const response = await fetch(SERVER_URL + "/odp/" + odp.id + "/stopStapling", {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(params)
    });

    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(updatedStapling());
        dispatch(showSuccess("function.operationSuccess"));
      } else {
        dispatch(enableProcessErrors());
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableProcessErrors());
    }
  }
};

export function getComponentsAPI(odp, process, retrieveLotInfo) {
  return async dispatch => {
    dispatch(enableReloading());
    const response = await fetch(SERVER_URL + '/odp/' + odp.id + '/process/' + process.id + '/component/', {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      }
    });
    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(fillComponents({ data: data }))
        if(retrieveLotInfo && process.startDate!==null && process.endDate===null){
          for(let c in data){
              if(data[c].consumptions.length > 0 && data[c].lotRequired){
                  dispatch(loadLotInfoAPI(odp, process, data[c].id, data[c].consumptions[0].id, data[c].consumptions[0].lot, false))
              }
          }
      }
      } else {
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableProcessErrors());
    }
  }
};

export function loadLotInfoAPI(odp, process, compId, consId, lot, stapling) {
  return async dispatch => {
    dispatch(enableReloading());
    let url;
        if (!stapling) {
          url = '/odp/' + odp.id + '/process/' + process.id + '/component/' + compId + '/lotInfo/' + consId
      } else {
          url = '/odp/' + odp.id + '/stapling/component/' + compId + '/lotInfo/' + consId
      }
      const response = await fetch(SERVER_URL + url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      }
    });
    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(getLotInfoSuccess({data: data, lot: lot}));
      } else {
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableProcessErrors());
    }
  }
};

export function getStaplingComponentsAPI(odp, retrieveLotInfo) {
  return async dispatch => {
    dispatch(enableReloading());
    const response = await fetch(SERVER_URL + '/odp/' + odp.id + '/stapling/components/', {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      }
    });
    try {
      const data = await response.json()
      if (response.status === 200) {
        dispatch(fillStaplingComponents(data))
        if (retrieveLotInfo && odp.startDate!==null && odp.endDate===null) {
          for (let c in data) {
            if (data[c].consumptions.length > 0 && data[c].lotRequired) {
              dispatch(loadLotInfoAPI(odp, false, data[c].id, data[c].consumptions[0].id, data[c].consumptions[0].lot, odp.stapling))
            }
          }
        }
      } else {
        dispatch(showError(data.message));
      }
    } catch (e) {
      if (response.status === 401) {
        dispatch(refreshAPI())
        dispatch(showError(customLabel("function.sessionExpired")));
      } else {
        dispatch(showError(e.message));
      }
      dispatch(enableProcessErrors());
    }
  }
};

export function justifyVarsAPI(odp, process, processVar, causalizationObj, user, machine) {
  return async dispatch => {
      dispatch(enableLoading());
      let params = { value: causalizationObj, user: user, machine: machine }
      try {
          const response = await fetch(SERVER_URL + "/odp/" + odp.id + "/process/" + process.id + "/var/" + processVar.id+"/justify", {
              mode: 'cors',
              method: 'PUT',
              headers: {
                  'Content-Type': 'application/json;charset=utf-8',
              },
              body: JSON.stringify(params)
          });
          const data = await response.json()
          if (response.status === 200) {
              dispatch(updatedProcessVar(data));
              dispatch(showSuccess("function.operationSuccess"));
          } else {
              dispatch(enableVarErrors({ varCheck: false }));
              dispatch(showError(data.message));
          }
      } catch (e) {
          dispatch(enableVarErrors({ varCheck: false }));
      }
  }
};