import { createSlice } from "@reduxjs/toolkit";
import { SERVER_NOT_RESPONDING_MESSAGE, SERVER_URL } from "../../constants";
import { logout, refreshAPI, setServerNotResponding } from "../main/slice";
import { resetErrors as resetNotificationErrors, showError, showSuccess } from "../notifications/slice";
import { customLabel } from "../utility/customLabel";

export const initialState = {
    loading: false,
    hasErrors: false,
    editmode: false,
    configuration: false,
    configurationedit: false,
    incremental: true
};

const configurationSlice = createSlice({
    name: "configuration",
    initialState,
    reducers: {
        resetState: (state) => {
            Object.assign(state, initialState);
        },
        reset: (state) => {
            state.configuration = false;
            state.configurationedit = false;
            state.editmode = false;
            state.isCreated = false;
        },
        getting: (state) => {
            state.isCreated = false;
            state.loading = true;
            state.editmode = false;
            state.hasErrors = false;
        },
        getSuccess: (state, { payload }) => {
            state.configuration = payload;
            state.loading = false;
        },
        resetEdit: (state) => {
            state.editmode = true;
            state.configurationedit = state.configuration;
        },
        edit: (state) => {
            state.editmode = true;
            state.configurationedit = state.configuration;
        },
        cancelEdit: (state) => {
            state.editmode = false;
            state.configurationedit = false;
        },
        change: (state, { payload }) => {
            let u = state.configurationedit
            if (payload.name === 'staplingConfig') {
                let staplingName = u.staplingName ? u.staplingName : ''
                let staplingConfig = payload.value.split(',').map(e => e.trim()).filter(e => e !== '').map(e => e)
                u[payload.name] = [staplingName, ...staplingConfig].join(',')
            } else if (payload.name === 'staplingName') {
                u[payload.name] = payload.value;
                let staplingName = u.staplingName ? u.staplingName : '';
                let staplingConfig = u.staplingConfig ? u.staplingConfig.split(',').slice(1) : [];
                u.staplingConfig = staplingConfig.length > 0 ? [staplingName, ...staplingConfig].join(',') : staplingName
            } else {
                u[payload.name] = payload.value
            }
            state.configurationedit = u;
            //se disabilito componenti disabilito anche verifica lotto
            if (!state.configurationedit.enableComponents) {
                state.configurationedit.verifyComponentLot = false;
                state.configurationedit.enableFreeLotComponents = false;
                state.configurationedit.componentUrl = "";
                state.configurationedit.forceComponent = false;
                state.configurationedit.forceWithVerifyComponent = false;
                state.configurationedit.changeComponent = false;
            }
            //se disabilito "abilita colore popup giustifica evento, metto a false il colore"
            if (!state.configurationedit.enableJustifyDialogColor) {
                state.configurationedit.justifyDialogColor = "";
            }
        },
        updating: (state) => {
            state.loading = true;
            state.hasErrors = false;
        },
        updateSuccess: (state, { payload }) => {
            state.configuration = payload;
            state.loading = false;
            state.editmode = false;
        },
        enableErrors: (state) => {
            state.loading = false;
            state.hasErrors = true;
        },
        addLabelSuccess: (state) => {
            state.reload = true
            state.loading = false
        },
        getLabelsSucess: (state, { payload }) => {
            state.configuration.labels = payload
            state.reload = false
        },
        deleteLabelSuccess: (state) => {
            state.reload = true
            state.loading = false
        }
    },
});

export const { reset, getting, getSuccess, edit, cancelEdit,
    change, updating, updateSuccess, resetEdit, enableErrors, resetState, addLabelSuccess, getLabelsSucess, deleteLabelSuccess } = configurationSlice.actions;
export const configurationSelector = (state) => state.configuration;
export default configurationSlice.reducer;

export function updateAPI(configuration, logoFile, labelFile) {
    let access_token = ""
    if (localStorage.getItem('bishop_current_user') != null) {
        access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
    }
    const formData = new FormData();
    //serve per valorizzare label e logo a seconda che i parametri siano null oppure no
    let label = null;
    if (labelFile !== null) {
        label = labelFile[0]
    } else {
        label = null
    }
    let logo = null;
    if (logoFile !== null) {
        logo = logoFile[0]
    } else {
        logo = null
    }
    formData.append('logoFile', logo)
    formData.append('labelFile', label)
    formData.append('name', configuration.name)
    formData.append('note', configuration.note)
    formData.append('evoUrl', configuration.evoUrl)
    formData.append('deleteLabel', configuration.deleteLabel)
    formData.append('deleteLogo', configuration.deleteLogo)
    formData.append('showUsers', configuration.showUsers)
    formData.append('askUserEvent', configuration.askUserEvent)
    formData.append('showArticleOrder', configuration.showArticleOrder)
    formData.append('showOdpDeliveryTime', configuration.showOdpDeliveryTime)
    formData.append('generatorLocation', configuration.generatorLocation)
    formData.append('generatorTopLevel', configuration.generatorTopLevel)
    formData.append('showFilters', configuration.showFilters)
    formData.append('showCustomerInterface', configuration.showCustomerInterface)
    formData.append('enableMaintenance', configuration.enableMaintenance)
    formData.append('enableComponents', configuration.enableComponents)
    formData.append('verifyComponentLot', configuration.verifyComponentLot)
    formData.append('enableFreeLotComponents', configuration.enableFreeLotComponents)
    formData.append('scrapReasonComponents', configuration.scrapReasonComponents)
    formData.append('forceComponent', configuration.forceComponent)
    formData.append('showAlerts', configuration.showAlerts)
    formData.append('enableInputOperator', configuration.enableInputOperator)
    formData.append('showExtMachines', configuration.showExtMachines)
    formData.append('showExtTimeline', configuration.showExtTimeline)
    formData.append('decimals', configuration.decimals)
    formData.append('enableInsertExtCode', configuration.enableInsertExtCode)
    formData.append('odpSortOrder', configuration.odpSortOrder)
    formData.append('labelTimeline', configuration.labelTimeline)
    formData.append('labelArticleOpView', configuration.labelArticleOpView)
    formData.append('labelWorkcentreOpView', configuration.labelWorkcentreOpView)
    formData.append('labelMachineOpView', configuration.labelMachineOpView)
    formData.append('labelOperatorOpView', configuration.labelOperatorOpView)
    formData.append('plannerDaysLimit', configuration.plannerDaysLimit)
    formData.append('labelOdpOpView', configuration.labelOdpOpView)
    formData.append('labelOdpDetailsOpView', configuration.labelOdpDetailsOpView)
    formData.append('dataRetentionCloseOdpDays', configuration.dataRetentionCloseOdpDays)
    formData.append('blockingChecks', configuration.blockingChecks)
    formData.append('labelTimelineMachines', configuration.labelTimelineMachines)
    formData.append('labelTimelineWorkcentres', configuration.labelTimelineWorkcentres)
    formData.append('reportUrl', configuration.reportUrl)
    formData.append('reportTemplates', configuration.reportTemplates)
    formData.append('componentUrl', configuration.componentUrl)
    formData.append('dataRetentionVarsOpenOdpsDays', configuration.dataRetentionVarsOpenOdpsDays)
    formData.append('dataRetentionDataOpenOdpsDays', configuration.dataRetentionDataOpenOdpsDays)
    formData.append('componentDecimals', configuration.componentDecimals)
    formData.append('staplingConfig', configuration.staplingConfig ?? '')
    formData.append('staplingCharSeparator', configuration.staplingCharSeparator ?? '')
    formData.append('staplingIncrPositions', configuration.staplingIncrPositions ?? '')
    formData.append('staplingSetupTimeMode', configuration.staplingSetupTimeMode ?? '')
    formData.append('componentPercentualeOk', configuration.componentPercentualeOk ?? '')
    formData.append('showSingleOdp', configuration.showSingleOdp)
    formData.append('showUserEvents', configuration.showUserEvents)
    formData.append('forceWithVerifyComponent', configuration.forceWithVerifyComponent)
    formData.append('showOdpQtyPreview', configuration.showOdpQtyPreview)
    formData.append('changeComponent', configuration.changeComponent)
    formData.append('opViewEnableOdpSearch', configuration.opViewEnableOdpSearch)
    formData.append('opViewOdpListSize', configuration.opViewOdpListSize)
    formData.append('editComponent', configuration.editComponent)
    formData.append('hideComponentLot', configuration.hideComponentLot)
    formData.append('showCloseLot', configuration.showCloseLot)
    formData.append('showOdpRemainingQuantity', configuration.showOdpRemainingQuantity)
    formData.append('showLotRemainingQuantity', configuration.showLotRemainingQuantity)
    formData.append('sendCurrentMachineEvent', configuration.sendCurrentMachineEvent)
    formData.append('machineEventReasonMode', configuration.machineEventReasonMode)
    formData.append('componentUseAlwaysFactor', configuration.componentUseAlwaysFactor)
    formData.append('retrieveLotInfo', configuration.retrieveLotInfo)
    formData.append('insertArticleIfForcedLot', configuration.insertArticleIfForcedLot)
    formData.append('enableUnstaple', configuration.enableUnstaple)
    formData.append('autoPopupJustify', configuration.autoPopupJustify)
    formData.append('enableCheckDialogColor', configuration.enableCheckDialogColor)
    formData.append('enableJustifyDialogColor', configuration.enableJustifyDialogColor)
    formData.append('justifyDialogColor', configuration.justifyDialogColor)
    formData.append('singleUserEvent', configuration.singleUserEvent)
    formData.append('variablePercentualeOk', configuration.variablePercentualeOk ?? '')

    return async dispatch => {
        dispatch(updating())
        const response = await fetch(SERVER_URL + '/api/configuration', {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + access_token
            },
            body: formData,
        });
        try {
            const data = await response.json()
            if (response.status === 200) {
                dispatch(updateSuccess(data))
                dispatch(showSuccess("function.operationSuccess"))
            } else if (response.status === 403) {
                dispatch(logout())
            } else {
                dispatch(showError(data.message))
                dispatch(enableErrors())
            }
        } catch (e) {
            if (response.status === 401) {
                dispatch(refreshAPI())
                dispatch(showError(customLabel("function.sessionExpired")));
            } else {
                dispatch(showError(e.message));
            }
            dispatch(enableErrors());
        }
    }
}
export function getAPI() {
    return async (dispatch) => {
        dispatch(getting());
        try {
            const response = await fetch(SERVER_URL + "/configuration/", {
                mode: "cors",
                method: "GET",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                },
            });
            const data = await response.json();
            dispatch(setServerNotResponding(false));
            dispatch(resetNotificationErrors());
            if (response.status === 200) {
                dispatch(getSuccess(data));
            } else if (response.status === 403) {
                dispatch(logout());
            } else {
                dispatch(showError(data.message));
                dispatch(enableErrors())
            }
        } catch (e) {
            dispatch(enableErrors());
            dispatch(setServerNotResponding(true));
            dispatch(showError(SERVER_NOT_RESPONDING_MESSAGE));
        }
    };
}

export function getLabelsAPI(configurationId) {
    let access_token = ""
    if (localStorage.getItem('bishop_current_user') != null) {
        access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
    }
    return async dispatch => {
        const response = await fetch(SERVER_URL + '/api/configuration/' + configurationId + '/label', {
            mode: 'cors',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + access_token
            },
        });
        try {
            const data = await response.json()
            if (response.status === 200) {
                dispatch(getLabelsSucess(data))
                dispatch(showSuccess("function.operationSuccess"))
            } else if (response.status === 403) {
                dispatch(logout())
            } else {
                dispatch(showError(data.message))
                dispatch(enableErrors())
            }
        } catch (e) {
            if (response.status === 401) {
                //dispatch(refreshAPI())
                dispatch(showError(customLabel("function.sessionExpired")));
            } else {
                dispatch(showError(e.message));
            }
            dispatch(enableErrors());
        }
    }
}

export function addLabelAPI(label, configurationId) {
    let access_token = ""
    if (localStorage.getItem('bishop_current_user') != null) {
        access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
    }
    return async dispatch => {
        let params = { label: label }
        const response = await fetch(SERVER_URL + '/api/configuration/' + configurationId + '/label', {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + access_token
            },
            body: JSON.stringify(params)
        });
        try {
            const data = await response.json()
            if (response.status === 200) {
                dispatch(addLabelSuccess())
                dispatch(showSuccess("function.operationSuccess"))
            } else if (response.status === 403) {
                dispatch(logout())
            } else {
                dispatch(showError(data.message))
                dispatch(enableErrors())
            }
        } catch (e) {
            if (response.status === 401) {
                //dispatch(refreshAPI())
                dispatch(showError(customLabel("function.sessionExpired")));
            } else {
                dispatch(showError(e.message));
            }
            dispatch(enableErrors());
        }
    }
}

export function deleteLabelAPI(labelId, configurationId) {
    let access_token = ""
    if (localStorage.getItem('bishop_current_user') != null) {
        access_token = JSON.parse(localStorage.getItem('bishop_current_user')).access_token
    }
    return async dispatch => {
        const response = await fetch(SERVER_URL + '/api/configuration/' + configurationId + '/label/' + labelId, {
            mode: 'cors',
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + access_token
            },
        });
        try {
            const data = await response.json()
            if (response.status === 200) {
                dispatch(deleteLabelSuccess())
                dispatch(showSuccess("function.operationSuccess"))
            } else if (response.status === 403) {
                dispatch(logout())
            } else {
                dispatch(showError(data.message))
                dispatch(enableErrors())
            }
        } catch (e) {
            if (response.status === 401) {
                //dispatch(refreshAPI())
                dispatch(showError(customLabel("function.sessionExpired")));
            } else {
                dispatch(showError(e.message));
            }
            dispatch(enableErrors());
        }
    }
}