import {
    Button,
    IconButton,
    Input,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import WarningIcon from '@material-ui/icons/Warning';
import { Autocomplete } from "@material-ui/lab";
import _ from "lodash";
import moment from "moment";
import React from 'react';
import { adminFirstName, bishopFormats, evolutionFirstName } from '../../constants';
import PaperAutocomplete from '../utility/PaperAutocomplete';
import { customLabel } from "../utility/customLabel";
import { secondsToDhms } from "../utility/vari";

export const renderAlertCausalize = (v) => {
    let causalize = false
    if(v.reasons.length>0){
        if(v.value!==null && ((Number(v.value) > 0 && Number(v.value) !== v.causalizedQty) || (Number(v.value) !== 0 && v.causalizedQty === null))){
            causalize = true
        }
    }
    return causalize
}

/**
 * Storico di una variabile
 * Restituisce una lista di TableRow con timestamp, operatore (facoltativo), valore; per ogni variabile all'interno dell'array varList
 * @param {array} varList Elenco delle variabili da mostrare
 * @param {object} varToSee Variabile selezionata
 * @param {boolean} showOperator mostrare colonna con il nome del operatore
 * @param {int} decimals Mostra decimali
 * @returns 
 */
export const renderVarsDetails = (varList, varToSee, showOperator, decimals) => {
    if (varList && varList.length > 0 && varToSee) {
        return (
            varList.map((v) => {
                let key = _.uniqueId(v.value + "_" + v.timestamp + "_")
                return (
                    <TableRow key={key}>
                        <TableCell size="small">{v.value !== null && v.timestamp !== null ? moment(v.timestamp).format(bishopFormats.LTS) : null}</TableCell>
                        {showOperator ? (
                            <TableCell size="small">{v.first_name && v.first_name !== adminFirstName && v.first_name !== evolutionFirstName
                                ? v.first_name + " " + v.last_name : null}</TableCell>
                        ) : null}
                        <TableCell size="small">
                            {varToSee.type === "boolean" && v.value === "1" ? <CheckIcon /> : varToSee.type === "boolean" && v.value === "0" ? <ClearIcon /> :
                                varToSee.type === "number" ? Number(v.value).toFixed(decimals) : varToSee.type === "timer" ? secondsToDhms(v.value) : v.value}
                        </TableCell>
                    </TableRow>
                )
            })
        )
    } else {
        return (
            <TableRow>
                <TableCell colSpan={showOperator ? 3 : 2}>{customLabel("function.notAvailable").toUpperCase()}</TableCell>
            </TableRow>
        )
    }
};

export const calculateCausalizationRemainingQty = (choosenVar, vars, causalizationObj) => {
    if(vars&&choosenVar&&causalizationObj){
        return Number(choosenVar.value) - Number(choosenVar.causalizedQty) - causalizationObj.reduce((accumulator, currentValue) => accumulator + Number(currentValue.value), 0)
    }else {
        return false
    }
}

/**
 * Visualizza l'input per inserire la variabile scelta a seconda del tipo: numberico, stringa, booleano
 * @param {object} choosenVar 
 * @param {object} currentValue variabile per memorizzare il contenuto della variabile inserita
 * @param {function} handleChangeValueText funzione per la modifica di variabili stringa/numeriche
 * @param {function} handleChangeValueBool funzione per la modifica di variabili booleane
 * @param {function} handleChangeValueSelect funzione per la modifica di variabili oggetto
 * @param {boolean} loading variabile per disabilitare input e tasti vero falso per variabili booleane
 * @param {object} articles se valorizzato mostra autocomplete per inserire articolo, se false niente
 * @param {object} article
 * @param {function} onChangeArticle
 * @returns div con all'interno o un input o due tasti (vero/falso) per variabili booleane
 */
export const renderFormInsertVar = (choosenVar, currentValue, handleChangeValueText, handleChangeValueBool, handleChangeValueSelect, loading,
    articles, article, onChangeArticle, processTypeVar, renderProgressBar, confirmAsked, causalizeVar, vars,
    addRowToCausalize, removeRowToCausalize, onChange, causalizationObj) => {
    if (choosenVar) {
        if(causalizeVar){
            let remainingQty=calculateCausalizationRemainingQty(choosenVar, vars, causalizationObj)
            let articleToInsert = articles && articles.length > 0
            return (
                <div key={choosenVar.id} style={{ textAlign: "center" }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                        <h3>
                            {choosenVar.name} {choosenVar.serial ? " - " + choosenVar.serialNo : null} {choosenVar.measure && choosenVar.measure !== "" && choosenVar.measure !== null ? " (" + choosenVar.measure + ")" : null} 
                        </h3>
                    </div>
                    <div style={{
                            textAlign: "center",
                            marginTop: "20px"
                        }}>
                        <h3>{customLabel("processType.var.quantityToCausalize")} {remainingQty}</h3>
                        <Table style={{width: '100%', tableLayout: 'fixed'}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: '10%' }}>{customLabel("processType.var.quantity")}</TableCell>
                                    <TableCell style={{ width: articleToInsert ? '40%' : '80%' }} align="center">{customLabel("processType.var.reason")}</TableCell>
                                    {articleToInsert ? (
                                        <TableCell style={{ width: '40%' }} align="center">{customLabel("article.article")}</TableCell>
                                    ):null}
                                    <TableCell style={{ width: '10%' }}><IconButton disabled={remainingQty<1} onClick={() => addRowToCausalize()}><AddIcon fontSize="small" /></IconButton></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {causalizationObj.map((j, i) => {
                            return <TableRow key={"process_type_reason_"+i}>
                                <TableCell style={{ width: '10%' }}>
                                    <Input value={j.value} onChange={(e) => onChange(e, i, 'value')} type="numeric" />
                                </TableCell>
                                <TableCell style={{ width: articleToInsert ? '40%' : '80%' }} align="center">
                                    <Select style={{ width: articleToInsert ? '80%' : '40%' }} name="scrapReason" value={j.reason} onChange={(e) => onChange(e, i, 'reason')}>
                                        {choosenVar.reasons.map((r) => {
                                            return (
                                                <MenuItem key={r.reason.id} value={r.reason.name}>{r.reason.name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </TableCell>
                                {articleToInsert ? (
                                    <TableCell style={{ width: '40%' }} align="center">
                                        <Autocomplete
                                            PaperComponent={PaperAutocomplete}
                                            disabled={loading}
                                            style={{ display: "inline-block", width: "80%"}}
                                            value={j.article ? j.article : null}
                                            getOptionSelected={(option, value) => j.article.id !== null && j.article.id !== '' ? option.id === value.id : null}
                                            onChange={(event, value, reason) => onChangeArticle("article.id", value, reason, i)}
                                            options={articles}
                                            getOptionLabel={(option) => option.code + " - " + option.name}
                                            id="articleAutocomplete"
                                            renderInput={(params) => <TextField {...params} />
                                            }
                                        />
                                    </TableCell>
                                ) : null}
                                <TableCell style={{ width: '10%' }}>
                                    <IconButton onClick={(e) => removeRowToCausalize(i)}><ClearIcon fontSize="small" /></IconButton>
                                </TableCell>
                            </TableRow>
                            })}
                            </TableBody>
                        </Table>
                    </div>
                </div>
            )
        } else {
            return (
                <div key={choosenVar.id} style={{ textAlign: "center" }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                        <h3>
                            {choosenVar.name} {choosenVar.serial ? " - " + choosenVar.serialNo : null} {choosenVar.measure && choosenVar.measure !== "" && choosenVar.measure !== null ? " (" + choosenVar.measure + ")" : null}
                        </h3>
                        {choosenVar.name === processTypeVar && renderProgressBar ? renderProgressBar() : null}
                    </div>
                    {confirmAsked ?
                        <h3><Typography color="secondary">{customLabel("processType.var.confirmValue")}</Typography></h3>
                        : null}
                    <div style={choosenVar.type === "boolean" ?
                        {
                            marginTop: "10px",
                            display: "flex",
                            justifyContent: "space-around"
                        }
                        : {
                            textAlign: "center",
                            marginTop: "20px"
                        }}>
                        {choosenVar.type === "boolean" ? (
                            <React.Fragment>
                                <Button onClick={(e) => handleChangeValueBool(e, true)} color="primary" disabled={loading}
                                    variant={currentValue === true ? "contained" : "outlined"}>{customLabel("function.true")}
                                </Button>
                                <Button onClick={(e) => handleChangeValueBool(e, false)} color="secondary" disabled={loading}
                                    variant={currentValue === false ? "contained" : "outlined"}>{customLabel("function.false")}
                                </Button>
                            </React.Fragment>
                        ) : choosenVar.type === "string" ? (
                            <TextField
                                variant="outlined" name="operator" multiline rows={4} value={currentValue ? currentValue : ""}
                                onChange={handleChangeValueText} fullWidth
                            />
                        ) : (
                            <>
                            <Input disabled={loading}
                                inputRef={(input) => {
                                    if (input !== null) {
                                        input.focus();
                                    }
                                }}
                                fullWidth margin="dense" name="operator" error={currentValue === "" || currentValue === null}
                                value={currentValue ? currentValue : ""} type={choosenVar.type === "number" ? "number" : null} onChange={handleChangeValueText}>
                            </Input>
                            <br/>
                            {choosenVar.results && choosenVar.results.length>0?
                                <>
                                <h3>{customLabel("function.var")}</h3>
                                <Select fullWidth name="result" value={choosenVar.result.id} onChange={handleChangeValueSelect} error={!choosenVar.result}>
                                    {choosenVar.results.map((r) => {
                                        return (
                                            <MenuItem key={r.result.id} value={r.result.id}>{r.result.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                                </>
                            :null}
                            </>
                        )}
                    </div>
                    {articles && articles.length > 0 ? (
                        <React.Fragment>
                            <Autocomplete
                                PaperComponent={PaperAutocomplete}
                                disabled={loading}
                                style={{ display: "inline-block", width: "calc(100%)", marginTop: "20px" }}
                                value={article ? article : null}
                                getOptionSelected={(option, value) => article.id !== null && article.id !== '' ? option.id === value.id : null}
                                onChange={(event, value, reason) => onChangeArticle("article.id", value, reason, null)}
                                options={articles}
                                getOptionLabel={(option) => option.code + " - " + option.name}
                                id="articleAutocomplete"
                                renderInput={(params) => <TextField {...params} label={customLabel("article.article")} error={article ? false : true} />
                                }
                            />
                        </React.Fragment>
                    ) : null}
                </div>
            )
        }
    }
};

/**
 * Mostra la lista delle variabili disponibili per l inserimento
 * @param {boolean} operator usato per lo stile dei bottoni
 * @param {array} vars lista delle variabili
 * @param {function} handleChooseVar funzione attivata sul tasto inserisci per valorizzare la variabile desiderata
 * @param {int} decimals Mostra decimali
 * @returns tabella con l'elenco delle variabili disponibili per l'inserimento
 */
export const renderVarsListToInsert = (operator, vars, handleChooseVar, decimals, processTypeVar, editProcess) => {
    if (vars && vars.length > 0) {
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ padding: "7px" }}>
                            {customLabel("function.name")}
                        </TableCell>
                        <TableCell style={{ padding: "7px" }}>
                            {customLabel("function.serial").toUpperCase()}
                        </TableCell>
                        <TableCell style={{ padding: "7px" }}>
                            {customLabel("function.value")}
                        </TableCell>
                        <TableCell style={{ padding: "7px" }} align="right">
                            {customLabel("function.time")}
                        </TableCell>
                        <TableCell style={{ padding: "7px" }} align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {vars.map((v) => {
                        if ((operator && v.operatorEdit) || (!operator && v.adminEdit)) {
                            return (
                                <TableRow key={v.name + "_" + v.type + "_" + v.id}>
                                    <TableCell style={{ padding: "7px" }}>
                                        {v.name}
                                    </TableCell>
                                    <TableCell style={{ padding: "7px" }}>
                                        {v.serialNo}
                                    </TableCell>
                                    <TableCell style={{ padding: "7px" }}>
                                        {v.type === "boolean" && v.value === "false" ? <ClearIcon /> : v.type === "boolean" && v.value === "true" ? <CheckIcon /> :
                                            v.value !== null && v.type === "string" ? v.value : v.value !== null && v.type === "number" ? <div style={{display: 'flex', alignItems: 'center'}}>{Number(v.value.toFixed(decimals))} {v.causalizedQty!==null&&Number(v.value)!==Number(v.causalizedQty) ? <WarningIcon color={"secondary"} style={{marginLeft: '5px', marginBottom: '7px'}} /> : null} </div>:
                                                v.value !== null && v.type === "timer" ? secondsToDhms(v.value) : "-"}
                                    </TableCell>
                                    <TableCell style={{ padding: "7px" }} align="right">
                                        {v.value !== null && v.timestamp !== null ? moment(v.timestamp).format(bishopFormats.LTS) : null}
                                    </TableCell>
                                    <TableCell style={{ padding: "7px" }} align="right">
                                        <Button style={{
                                            padding: "15px",
                                            fontSize: "15pt !important"
                                        }} variant={operator ? "contained" : "text"} disabled={processTypeVar === v.name && editProcess.startDate === null} color="primary" onClick={(e) => handleChooseVar(v, false)}>{customLabel("button.insert")}</Button>
                                        <Button style={{
                                            padding: "15px",
                                            marginLeft: "15px",
                                            fontSize: "15pt !important"
                                        }} variant={operator ? "contained" : "text"} disabled={(!renderAlertCausalize(v) || (processTypeVar === v.name && editProcess.startDate === null))} color="primary" onClick={(e) => handleChooseVar(v, true)}>{customLabel("button.causalize")}</Button>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                        else return null;
                    })}
                </TableBody>
            </Table>
        )
    }
    else {
        return (
            <div>{customLabel("function.notAvailable").toUpperCase()}</div>
        )
    }
};
//restituisce il valore corrente della variabile associata alla lavorazione
//OLD????
export const renderCurrentValue = (varTypeId, vars) => {
    let value = 0;
    if (vars && vars.length > 0) {
        vars.forEach((v) => {
            if (varTypeId === v.type.id) {
                value = v.value;
            }
        })
    }
    return Math.round(value * 100) / 100;
};

/**
 * Restituisce una tabella con l'elenco variabili, tasto modifica e tasto dettagli per ogni riga (facolativi)
 * @param {object} odp 
 * @param {boolean} stapling 
 * @param {object} process 
 * @param {array} listVars 
 * @param {boolean} showHistoryIcon 
 * @param {boolean} showEditIcon 
 * @param {function} setVarsDetails funzione chiamata su icona storico. params:odpId, editProcessId, cv, machine, stapling
 * @param {function} onEditVar funzione chiamata su icona modifica. params: var
 * @param {int} decimals
 * @returns 
 */
export const renderListVars = (odp, stapling, process, listVars, showHistoryIcon, showEditIcon, setVarsDetails, onEditVar, decimals) => {
    if (listVars && listVars.length > 0) {
        return (
            listVars.map((v) => {
                return (
                    <TableRow key={v.id}>
                        <TableCell>{v.measure !== "" && v.measure !== null ? v.name + " (" + v.measure + ")" : v.name}</TableCell>
                        <TableCell>{v.serialNo && v.serialNo !== null ? v.serialNo : null}</TableCell>
                        <TableCell>{v.type === "boolean" && v.value === "false" ? <ClearIcon /> : v.type === "boolean" && v.value === "true" ? <CheckIcon /> :
                            v.value !== null && v.type === "string" ? v.value : v.value !== null && v.type === "number" ? Number(v.value).toFixed(decimals) :
                                v.value !== null && v.type === "timer" ? secondsToDhms(v.value) : customLabel("function.notAvailable").toUpperCase()}
                        </TableCell>
                        <TableCell>{v.value !== null && v.timestamp !== null ? moment(v.timestamp).format(bishopFormats.LTS) : null}</TableCell>
                        <TableCell>
                            {v.user && v.user.id !== null && v.user.username !== "admin" ? v.user.firstName + " " + v.user.lastName : null}
                        </TableCell>
                        <TableCell>
                            <IconButton size="small" color="primary"
                                onClick={!stapling ? () => setVarsDetails(odp.id, process.id, v, false, false) : () => setVarsDetails(odp.id, false, v, false, true, null)}
                                disabled={v.value === null || !showHistoryIcon}>
                                <VisibilityIcon />
                            </IconButton>
                            <IconButton size="small" color="primary" onClick={() => onEditVar(v)} disabled={v.value !== null || !showEditIcon}>
                                <EditIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                )
            })
        )
    } else {
        return (
            <TableRow>
                <TableCell colSpan={6} size="small">{customLabel("function.notAvailable").toUpperCase()}</TableCell>
            </TableRow>
        )

    }
}
/**
 * Restituisce una tabella con l'elenco variabili macchina, tasto storico (facolativo)
 * @param {object} odp 
 * @param {boolean} stapling 
 * @param {object} process 
 * @param {array} listVars 
 * @param {boolean} showHistoryIcon 
 * @param {function} setVarsDetails funzione chiamata su icona storico. params:odpId, editProcessId, cv, machine, stapling
 * @param {integer} decimals
 * @returns 
 */
export const renderListMachineVars = (odp, stapling, process, listVars, showHistoryIcon, setVarsDetails, decimals) => {
    if (listVars && listVars.length > 0) {
        return (
            listVars.map((v) => {
                let key = _.uniqueId(v.machine_code + "_" + v.value + "_" + v.timestamp + "_" + v.var_name + "_")
                return (
                    <TableRow key={key}>
                        <TableCell>{v.var_name}</TableCell>
                        <TableCell>{v.machine_code}</TableCell>
                        <TableCell>{v.type === "boolean" && v.value === "false" ? <ClearIcon /> : v.type === "boolean" && v.value === "true" ? <CheckIcon /> :
                            v.value !== null && v.type === "string" ? v.value : v.value !== null && v.type === "number" ? Number(v.value).toFixed(decimals) :
                                v.value !== null && v.type === "timer" ? secondsToDhms(v.value) : customLabel("function.notAvailable").toUpperCase()}
                        </TableCell>
                        <TableCell>{v.value !== null ? moment(v.timestamp).format(bishopFormats.LTS) : null}</TableCell>
                        {showHistoryIcon ? (
                            <TableCell>
                                <IconButton size="small" color="primary" disabled={v.value === null}
                                    onClick={!stapling ? () => setVarsDetails(odp.id, process.id, v, true, false, v.machine_id) : () => setVarsDetails(odp.id, false, v, true, true, v.machine_id)} >
                                    <VisibilityIcon />
                                </IconButton>
                            </TableCell>
                        ) : null}
                    </TableRow>
                )
            })
        )
    } else {
        return (
            <TableRow>
                <TableCell colSpan={5} size="small">{customLabel("function.notAvailable").toUpperCase()}</TableCell>
            </TableRow>
        )
    }
}
