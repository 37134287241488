import { Button } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { customLabel } from "../../utility/customLabel";
import { calculateCausalizationRemainingQty, renderFormInsertVar, renderVarsListToInsert } from "../../vars/utility";
import {
    addRowToCausalizationObj,
    changeCausalizationObj,
    changeCurrentValue,
    changeResult,
    disableFlagInsertVar,
    disableFlagVars,
    enableFlagInsertVar,
    insertStaplingVarsAPI,
    insertVarsAPI,
    justifyStaplingVarsAPI,
    justifyVarsAPI,
    operatorViewSelector,
    removeRowToCausalizationObj,
    setArticleToWaste,
} from "../slice";
import DialogOperator from "../utility/DialogOperator";
import { opViewStyles } from "../utility/customStyles";

const VarsDialog = ({ configuration, processTypeVar, renderProgressBar }) => {
    const customClasses = opViewStyles();
    const dispatch = useDispatch();
    const { currentValue, choosenVar, vars, loading, flagDialog, odp, operator, machine, process, modeStapling, staplingList,
        articleToWaste, staplingArticles, modeMaintenance, causalizeVar, causalizationObj } = useSelector(operatorViewSelector);
    const [confirmAsked, setConfirmAsked] = React.useState(false);

    //Aggiornamento dello proprietà current value tramite Input field
    const handleChangeValueText = (event) => {
        dispatch(changeCurrentValue({ value: event.target.value }));
    };
    //viene valorizzato lo stato currentValue che rappresnta il valore della var di tipo boolean
    const handleChangeValueBool = (event, value) => {
        dispatch(changeCurrentValue({ value: value }));
    };
    //viene valorizzato lo stato currentValue che rappresnta il valore della var di tipo object
    const handleChangeValueSelect = (event, value) => {
        dispatch(changeResult({ value: event.target.value }));
    };
    //button che seleziona la variable scelta per l inserimento
    const handleChooseVar = (varChoosen, causalize) => {
        dispatch(enableFlagInsertVar({var:varChoosen,causalize:causalize}))
        if(modeStapling && varChoosen.askArticle && staplingArticles.length===1){
            dispatch(setArticleToWaste({ articles: staplingArticles }))
        }
    };
    //API che inserisci la variabile usando il current value
    const handleInsertVar = () => {
        if(!choosenVar.askConfirmation || confirmAsked){
            setConfirmAsked(false)
            dispatch(insertVarsAPI(odp, process, choosenVar, currentValue, operator, machine, false, false, false, false))
        } else {
            setConfirmAsked(true)
        }
    };
    //API che inserisci la variabile per una pinatura usando il current value
    const handleInsertStaplingVar = () => {
        if(!choosenVar.askConfirmation || confirmAsked){
            setConfirmAsked(false)
            dispatch(insertStaplingVarsAPI(odp, choosenVar, currentValue, operator, machine, articleToWaste, false, false, false, false))
        } else {
            setConfirmAsked(true)
        }
    };

    const handleJustifyVar = () => {
        !modeStapling? dispatch(justifyVarsAPI(odp, process, choosenVar, causalizationObj, operator, machine)) : dispatch(justifyStaplingVarsAPI(odp, choosenVar, causalizationObj, operator, machine))
    };

    const onChangeArticleAutocomplete = (name, value, reason, justifyItem) => {
        if(justifyItem!==null){
            let obj
            if (reason === "clear") {
                obj = { value: "", key: justifyItem, item: "article" }
            } else {
                let indexArticle = staplingArticles.findIndex((a) => {
                    return a.id.toString() === value.id.toString()
                })
                obj = { value: staplingArticles[indexArticle], key: justifyItem, item: "article" }
            }
            dispatch(changeCausalizationObj(obj));
        }else {
            let obj
            if (reason === "clear") {
                obj = { articles: false, value: false }
            } else {
                obj = { articles: staplingArticles, value: value.id }
            }
            dispatch(setArticleToWaste(obj))
        }
    };
    const renderListVarsActions = () => {
        return (
            <Button className={customClasses.dialogButton} disabled={loading} variant="contained" onClick={() => dispatch(disableFlagVars())} color="secondary">{customLabel("button.close")}</Button>
        )
    }

    const checkCausalizationFields = () => {
        if(causalizationObj){
            let art = causalizationObj.find((element) => element.article === false || element.article === '');
            let reason = causalizationObj.find((element) => element.reason === "");
            if(calculateCausalizationRemainingQty(choosenVar, vars, causalizationObj)<0 || causalizationObj.length===0 || (art!==undefined&&choosenVar.askArticle&&modeStapling) || reason!==undefined){
                return true
            } else {
                return false
            }
        }else{
            return true
        }
    }

    const renderInsertVarActions = () => {
        let threshold = configuration.variablePercentualeOk ? configuration.variablePercentualeOk / 100 : false
        let declaredPieces = !modeStapling ? (process.totalPieces !== null ? process.totalPieces : process.validPieces) : (odp.totalPieces !== null ? odp.totalPieces : odp.validPieces - odp.rejectedPieces)
        return (
            <React.Fragment>
                <Button className={customClasses.dialogButton} variant="contained" onClick={causalizeVar? handleJustifyVar : !modeStapling ? handleInsertVar : handleInsertStaplingVar}
                    color="primary"
                    disabled={(choosenVar && choosenVar.results.length>0 && !choosenVar.result.id) || (causalizeVar && checkCausalizationFields()) || (!causalizeVar && (currentValue === null || currentValue === "" || loading ||
                        (!articleToWaste && choosenVar && modeStapling && choosenVar.operatorEdit && choosenVar.operatorView && staplingList && choosenVar.askArticle))) ||
                        (!modeStapling && choosenVar.name === processTypeVar && threshold && ((currentValue > process.quantity - declaredPieces + (process.quantity * threshold) && currentValue > 0) || Number(currentValue) + declaredPieces < 0)) ||
                        (modeStapling && choosenVar.name === processTypeVar && threshold && ((currentValue > odp.quantity - declaredPieces + (odp.quantity * threshold) && currentValue > 0) || Number(currentValue) + declaredPieces < 0))}>
                    {customLabel("button.confirm")}
                </Button>
                <Button className={customClasses.dialogButton} disabled={loading} variant="contained" onClick={() => dispatch(disableFlagInsertVar())}
                    color="secondary">{customLabel("button.back")}
                </Button>
            </React.Fragment>
        )
    }

    const addRowToCausalize = () => {
        dispatch(addRowToCausalizationObj())
    }

    const removeRowToCausalize = (i) => {
        dispatch(removeRowToCausalizationObj(i))
    }
    const onChangeCausalize = (event, key, item) => {
        let value = event.target.value
        dispatch(changeCausalizationObj({ value: value, key: key, item: item }));
    };

    return (
        <React.Fragment>
            {/*Dialog per elencare le variabili da inserire*/}
            <DialogOperator flag={flagDialog.flagVars ? true : false} title={!modeMaintenance ? customLabel("processType.var.listVariables") : customLabel("maintenance.declarationsList")}
                form={() => renderVarsListToInsert(true, vars, handleChooseVar, configuration.decimals)}
                actions={() => renderListVarsActions()} transparentBackround={false} />
            {/*Dialog per inserire una determinata variabili scelta dal dialog precedente*/}
            <DialogOperator flag={flagDialog.flagInsertVar ? true : false} title={!modeMaintenance && !causalizeVar ? customLabel("processType.var.insertVariable") : causalizeVar? customLabel("processType.var.causalizeVariable") : customLabel("maintenance.insertDeclaration")}
                form={() => renderFormInsertVar(choosenVar, currentValue, handleChangeValueText, handleChangeValueBool, handleChangeValueSelect, loading,
                    choosenVar && modeStapling && choosenVar.operatorEdit && choosenVar.operatorView && staplingList && choosenVar.askArticle ? staplingArticles : false, articleToWaste, onChangeArticleAutocomplete, processTypeVar, renderProgressBar, confirmAsked,
                    causalizeVar, vars, addRowToCausalize, removeRowToCausalize, onChangeCausalize, causalizationObj)}
                actions={() => renderInsertVarActions()} transparentBackround={false} />
        </React.Fragment >
    )
}

export default VarsDialog;
